import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { NavigateFunction } from 'react-router';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { authApiSlice } from '../../auth/authApiSlice';
import { setNanoBotInvisible } from '../../nanoBot/nanoBotSlice';
import { segmentsListApiSlice } from '../list/segmentsListApiSlice';
import { segmentsApi } from '../segmentApi';
import { getSegment } from '../segmentSelectors';
import { fetchSegmentDetails, saveSegmentFailure, saveSegmentSuccess } from '../segmentSlice';
import { SegmentGroup, SegmentResponse, SegmentState, SegmentStatuses } from '../types';
import { transformBucketsToTargetCombinations } from '../utils';

export function* segmentNanoBotSave(action: PayloadAction<{ navigate: NavigateFunction }>) {
  try {
    const segment: SegmentState = yield select(getSegment);
    const { navigate } = action.payload;
    const { data: user } = yield select(authApiSlice.endpoints.userProfile.select(null));
    const buckets = [segment.currentBucket];
    const formatDate = (date: Date): string => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const currentDate = new Date();
    const uniqueName = `Test ST ${formatDate(currentDate)}`;

    const targetCombinations = transformBucketsToTargetCombinations(buckets);

    const segmentGroups: SegmentGroup[] = yield call(segmentsApi.segmentGroupsBySspApi, 'PM');
    const defaultGroup = segmentGroups.find((group) => group.groupName === 'Default');
    const defaultGroupId = defaultGroup ? defaultGroup.id : null;

    const segmentData = {
      name: uniqueName,
      status: SegmentStatuses.planning,
      userId: user.id,
      companyId: user.companyId,
      teamId: user.teamId,
      ssp: 'PM',
      countries: ['FR', 'DE', 'GB', 'US'],
      targetCombinations: targetCombinations,
      sensitiveSubjects: segment.sensitiveSubjects.sensitiveSubjectsValues,
      description: '',
      async: true,
      realtime: segment.realtime,
      parentId: null,
      group: defaultGroupId,
      hideNameForPartner: false,
      accuracyThreshold: 'low',
      domainInclusionListId: '',
      domainExclusionListId: '',
    };

    const response: SegmentResponse = yield call(segmentsApi.saveSegment, segmentData);

    yield put(saveSegmentSuccess(response));
    yield put(setNanoBotInvisible());
    yield put(fetchSegmentDetails({ id: response.id }));
    yield navigate(`/segment/${response.id}`, { replace: true });
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
  } catch (e) {
    notifyError(e);
    yield put(
      saveSegmentFailure({
        message: i18next.t('errors.segmentSaveFailed'),
      }),
    );
  }
}
