import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { pixelApi } from '../pixelApi';
import { pixelListApiSlice } from '../pixelListApiSlice';
import { getPixelToDeleteId } from '../pixelSelectors';
import { deletePixelFailure, deletePixelSuccess } from '../pixelSlice';

export function* deletePixelSaga() {
  try {
    const pixelToDeleteId: string = yield select(getPixelToDeleteId);

    yield call(pixelApi.deletePixel, pixelToDeleteId);

    yield put(deletePixelSuccess());
    yield put(pixelListApiSlice.util.invalidateTags(['Pixel']));
  } catch (e) {
    notifyError(e);

    yield put(
      deletePixelFailure({
        message: i18next.t('pages.pixel.errors.pixelDeleteFailed'),
      }),
    );
  }
}
