import { AdvertiserForm } from '../../modules/advertiser/form/AdvertiserForm';
import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

type AdvertiserFormDialogProps = DialogBaseProps & {
  title: string;
  id?: string;
  name?: string;
  agree?: () => void;
};

export const AdvertiserFormDialog = ({
  open,
  title,
  id,
  name,
  onClose,
  agree,
}: AdvertiserFormDialogProps) => {
  return (
    <NanoDialog open={open} title={title} width={800}>
      <AdvertiserForm onClose={onClose} refetch={agree} id={id} name={name} />
    </NanoDialog>
  );
};
