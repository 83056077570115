import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from '../../../services/logService';
import { advertiserApi } from '../advertiserApi';
import { advertiserListApiSlice } from '../advertiserListApiSlice';
import { getAdvertiser } from '../advertiserSelectors';
import { updateAdvertiserFailure, updateAdvertiserSuccess } from '../advertiserSlice';
import { AdvertiserFormData, AdvertiserState } from '../constants';

export function* updateAdvertiserSaga() {
  try {
    const advertiser: AdvertiserState = yield select(getAdvertiser);

    const data: AdvertiserFormData = {
      name: advertiser.name,
      url: advertiser.url,
      country: advertiser.country,
      companyId: advertiser.companyId,
      teamId: advertiser.teamId,
      userId: advertiser.userId,
    };

    yield call(advertiserApi.updateAdvertiser, advertiser.id, data);

    yield put(advertiserListApiSlice.util.invalidateTags(['Advertiser']));
    yield put(updateAdvertiserSuccess());
  } catch (e) {
    notifyError(e);

    yield put(
      updateAdvertiserFailure({
        message: i18next.t('pages.advertiser.errors.advertiserUpdateFailed'),
      }),
    );
  }
}
