import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { advertiserApi } from '../advertiserApi';
import { advertiserListApiSlice } from '../advertiserListApiSlice';
import { getAdvertiserToDeleteId } from '../advertiserSelectors';
import { deleteAdvertiserFailure, deleteAdvertiserSuccess } from '../advertiserSlice';

export function* deleteAdvertiserSaga() {
  try {
    const advertiserToDeleteId: string = yield select(getAdvertiserToDeleteId);

    yield call(advertiserApi.deleteAdvertiser, advertiserToDeleteId);

    yield put(deleteAdvertiserSuccess());
    yield put(advertiserListApiSlice.util.invalidateTags(['Advertiser']));
  } catch (e) {
    notifyError(e);

    yield put(
      deleteAdvertiserFailure({
        message: i18next.t('pages.advertiser.errors.advertiserDeleteFailed'),
      }),
    );
  }
}
