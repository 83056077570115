import React from 'react';

import { OrgSegmentNode } from '../../types';
import { PanelContentProps } from './PanelContent';

export const SegmentPanel = ({ node }: PanelContentProps) => {
  if (!node) {
    return null;
  }

  return (
    <>
      <section className="mx-auto flex flex-col items-start gap-1 pb-8">
        <h2 className="text-primary-dark-purple-100 text-base-l font-bold">Segment</h2>
        <p className="max-w-2xl text-base font-light">{node.name}</p>
      </section>

      <section className="mx-auto flex flex-col items-start gap-1">
        <h2 className="text-primary-dark-purple-100 text-base-l font-bold">Intent Prompts</h2>

        <div className="flex flex-col gap-4 w-full">
          {(node as OrgSegmentNode).data.intentClusters[0].sentences.map((sentence, index) => (
            <div
              key={index}
              className="flex flex-row gap-2 w-full border-b border-opacity-25 border-nano-pca-purple pb-4"
            >
              <div className="text-base-l w-2/3">
                <p className=""> {sentence} </p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
