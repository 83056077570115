import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { PixelFormData, PixelItem, PixelState } from '../constants';
import { pixelApi } from '../pixelApi';
import { pixelListApiSlice } from '../pixelListApiSlice';
import { getPixel } from '../pixelSelectors';
import { savePixelFailure, savePixelSuccess } from '../pixelSlice';

export function* savePixelSaga() {
  try {
    const pixel: PixelState = yield select(getPixel);
    const pixelData: PixelFormData = {
      name: pixel.name,
      advertiserId: pixel.advertiserId,
      companyId: pixel.companyId,
      teamId: pixel.teamId,
      userId: pixel.userId,
      piggyBacks: pixel.piggyBacks.filter((value) => value.trim() !== ''),
      type: pixel.type,
    };
    const response: PixelItem = yield call(pixelApi.savePixel, pixelData);

    yield put(savePixelSuccess(response));
    yield put(pixelListApiSlice.util.invalidateTags(['Pixel']));
  } catch (e) {
    notifyError(e);

    yield put(
      savePixelFailure({
        message: i18next.t('pages.pixel.errors.pixelSaveFailed'),
      }),
    );
  }
}
