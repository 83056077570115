import ReactECharts from 'echarts-for-react';
import React from 'react';

import { OrgSentimentNode } from '../../types';
import { PanelContentProps } from './PanelContent';

export const SentimentPanel = ({ node }: PanelContentProps) => {
  const data = [
    { name: 'Positive', value: (node as OrgSentimentNode).sentimentDistribution.percentPositive },
    { name: 'Negative', value: (node as OrgSentimentNode).sentimentDistribution.percentNegative },
  ];

  const chartData = data.map((item) => ({
    name: item.name,
    value: item.value,
    itemStyle: {
      color: item.name === 'Positive' ? '#00bfbb' : '#c49cc8',
    },
  }));

  // ECharts options
  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)', // Show name, value, and percentage
    },
    series: [
      {
        name: 'Percentage Distribution',
        type: 'pie',
        radius: '50%',
        data: chartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        label: {
          show: true,
          fontFamily: 'Euclid Circular A', // Label font style
          fontSize: 14,
          fontWeight: 'normal',
          color: '#555555', // Label text color
        },
      },
    ],
  };

  if (!node) {
    return null;
  }

  return (
    <>
      <section className="mx-auto flex flex-col items-start gap-1 pb-8">
        <h2 className="text-primary-dark-purple-100 text-base-l font-bold">
          Sentiment Distribution
        </h2>
        <p className="max-w-2xl text-base font-light">
          Distribution across urls targeted with intent prompts from this intent group
        </p>
      </section>

      <section>
        <div className="flex flex-row gap-2 w-full pb-4">
          <div className="text-base-l w-2/3">
            <p className="text-base-s">Number of Urls with positive sentiment</p>
          </div>
          <div className="w-1/3 px-1">
            <p className="text-base-s justify-end flex">
              {(node as OrgSentimentNode).sentimentDistribution.positiveCount}
            </p>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="flex flex-row gap-2 w-full pb-4">
          <div className="text-base-l w-2/3">
            <p className="text-base-s">Number of Urls with negative sentiment</p>
          </div>
          <div className="w-1/3 px-1">
            <p className="text-base-s justify-end flex">
              {(node as OrgSentimentNode).sentimentDistribution.negativeCount}
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className=" rounded-2xl border border-nano-pca-green border-opacity-25">
          <ReactECharts option={option} />
        </div>
      </section>
    </>
  );
};
