import { HierarchyNode } from 'd3-hierarchy';

import {
  OrgCategoryNode,
  OrgCategoryRootNode,
  OrgDomainNode,
  OrgDomainRootNode,
  OrgIntentGroupNode,
  OrgNodeData,
  OrgSegmentNode,
  OrgSentimentNode,
} from '../../types';
import { CategoryNodeContent } from './CategoryNodeContent';
import { CategoryRootNodeContent } from './CategoryRootNodeContent';
import { DomainNodeContent } from './DomainNodeContent';
import { DomainRootNodeContent } from './DomainRootNodeContent';
import { IntentGroupNodeContent } from './IntentGroupNodeContent';
import { SegmentNodeContent } from './SegmentNodeContent';
import { SentimentNodeContent } from './SentimentNodeContent';

type NodeContentProps = {
  node: HierarchyNode<OrgNodeData>;
};

export const NodeContent = ({ node }: NodeContentProps) => {
  // How beautiful is this!!? Stay awhile and enjoy!
  if (node.data.type === 'Segment') {
    return <SegmentNodeContent node={node.data as OrgSegmentNode} />;
  }

  if (node.data.type === 'IntentGroup') {
    return <IntentGroupNodeContent node={node.data as OrgIntentGroupNode} />;
  }

  if (node.data.type === 'Category') {
    return <CategoryNodeContent node={node.data as OrgCategoryNode} />;
  }

  if (node.data.type === 'CategoryRoot') {
    return <CategoryRootNodeContent node={node.data as OrgCategoryRootNode} />;
  }

  if (node.data.type === 'Sentiment') {
    return <SentimentNodeContent node={node.data as OrgSentimentNode} />;
  }

  if (node.data.type === 'Domain') {
    return <DomainNodeContent node={node.data as OrgDomainNode} />;
  }

  if (node.data.type === 'DomainRoot') {
    return <DomainRootNodeContent node={node.data as OrgDomainRootNode} />;
  }

  // Fallback but this is unreachable
  return (
    <div className="border w-full h-full rounded-2xl p-2 bg-secondary-white">
      <p className="text-base-xl"> {node.name}</p>
    </div>
  );
};
