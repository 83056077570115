import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import {
  NanoBotChatRole,
  NanoBotResponse,
  NanoBotState,
  ResponseMessage,
} from 'modules/nanoBot/types';

import { nanoBotStorage } from './nanoBotStorage';

const initialState: NanoBotState = {
  isOpen: false,
  messagesHistory: nanoBotStorage.getFromNanoBotStorage(),
  currentMessage: '',
  isLoading: false,
  cta: nanoBotStorage.getCtaFromNanoBotStorage(),
};

export const nanoBotSlice = createSlice({
  name: 'nanoBot',
  initialState,
  reducers: {
    toggleNanoBotVisibility: (state: NanoBotState) => {
      state.isOpen = !state.isOpen;
    },
    setNanoBotInvisible: (state: NanoBotState) => {
      state.isOpen = false;
    },
    sendMessage: (state: NanoBotState, { payload }: PayloadAction<string>) => {
      state.isLoading = true;
      state.currentMessage = payload;
    },
    sendMessageHistoryUpdate: (state: NanoBotState, { payload }: PayloadAction<string>) => {
      state.messagesHistory = [
        ...state.messagesHistory,
        { role: NanoBotChatRole.User, content: payload },
      ];
    },
    clearMessageHistory: (state: NanoBotState) => {
      state.messagesHistory = [];
    },
    sendMessageSuccess: (state: NanoBotState, { payload }: PayloadAction<NanoBotResponse>) => {
      state.isLoading = false;
      const trimmedMessages: ResponseMessage[] = payload.chat.map((message) => {
        const newContent = message.content.replace(/###/g, '').trim();

        return {
          content: newContent,
          role: message.role,
        };
      });

      const combinedMessages = [...state.messagesHistory, ...trimmedMessages];

      state.messagesHistory = Array.from(
        new Map(combinedMessages.map((msg) => [msg.content + msg.role, msg])).values(),
      );
      state.cta = payload.cta;

      nanoBotStorage.clearNanoBotStorage();
      nanoBotStorage.clearCtaNanoBotStorage();
      nanoBotStorage.saveToNanoBotStorage([...state.messagesHistory]);
      nanoBotStorage.saveCtaToNanoBotStorage(payload.cta);
    },
    sendMessageFailure: (state: NanoBotState) => {
      state.isLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getBriefUploadDataNanoBot: (state: NanoBotState, { payload }: PayloadAction<FormData>) => {
      state.isLoading = true;
    },
  },
});

const getNanoBot = ({ nanoBot }: AppState) => nanoBot;

export const getIsNanoBotOpen = (state: AppState) => getNanoBot(state).isOpen;
export const getNanoBotMessagesHistory = (state: AppState) => getNanoBot(state).messagesHistory;
export const getNanoBotLoading = (state: AppState) => getNanoBot(state).isLoading;
export const getNanoBotCallToAction = (state: AppState) => getNanoBot(state).cta;

export const {
  toggleNanoBotVisibility,
  sendMessage,
  sendMessageSuccess,
  sendMessageFailure,
  sendMessageHistoryUpdate,
  clearMessageHistory,
  setNanoBotInvisible,
  getBriefUploadDataNanoBot,
} = nanoBotSlice.actions;

export const nanoBot = nanoBotSlice.reducer;
