import { MappedCategory } from 'modules/segment/segmentForms/ManualStepForm/components/Categories/types';
import { TargetingOptionIds } from 'modules/segment/segmentForms/ManualStepForm/targetingOptions';
import {
  EmotionTargetOption,
  Entity,
  Sentiment,
} from 'modules/segment/segmentForms/ManualStepForm/types';

import { PerformanceMetrics } from './segmentApiTypes';
import { FileDescription } from './segmentForms/ManualStepForm/components/Keywords/components/UploadKeywords';

export const SegmentStatuses = {
  planning: 'planning', // draft
  pending: 'pending',
  active: 'active',
  expired: 'expired',
  deactivated: 'deactivated',
} as const;

type ObjectValues<T> = T[keyof T];

export type SegmentStatus = ObjectValues<typeof SegmentStatuses>;

export enum SentimentType {
  negative = 'negative',
  positive = 'positive',
}

export const IntervalFilters = {
  monthly: 'monthly',
  weekly: 'weekly',
  daily: 'daily',
} as const;

export type IntervalFilter = ObjectValues<typeof IntervalFilters>;

export enum SegmentMode {
  automated = 'automated',
  manual = 'manual',
}

export type SentimentVariant = (typeof SentimentType)[keyof typeof SentimentType];

export type AutomatedStepValues = {
  description: string | null;
};

export type AccuracyThresholdTextValues = 'low' | 'medium' | 'high';

export type CreateStepValues = AutomatedStepValues & {
  name: string;
  domainInclusionListId: string | null;
  domainExclusionListId: string | null;
  isIncluded?: boolean;
  ssp: string | null;
  sspType?: string | null;
  parentId: string | null;
  group: string | null;
  hideNameForPartner: boolean;
  async: boolean;
  realtime: boolean;
  dsp: string | null;
  dspSeatId: string | null;
  sspDealId: string | null;
  sspSeatId: string | null;
  sspSegmentId: string | null;
  expiredDate: string | null;
  debugUntil: string | null;
  debugUntilStatus: string;
  geo: string[];
  isEditable: boolean;
  labels: string[];
  accuracyThreshold: AccuracyThresholdTextValues;
  publicSegment: boolean;
};

export type ManualStepValues = {
  id: string | null;
  [TargetingOptionIds.keywords]: {
    isIncluded: boolean;
    isTouched: boolean;
    keywordsText: string;
    keywordsValues: string[];
    uploadedValues: FileDescription[];
    intentPrompt: boolean;
  };
  [TargetingOptionIds.categories]: {
    isIncluded: boolean;
    isTouched: boolean;
    categoriesValues: string[];
  };
  [TargetingOptionIds.sentiments]: {
    isIncluded: boolean;
    isTouched: boolean;
    sentimentsValue: Sentiment;
  };
  [TargetingOptionIds.languages]: {
    isIncluded: boolean;
    isTouched: boolean;
    languagesValues: Language['iso'][];
  };
  [TargetingOptionIds.entity]: {
    isIncluded: boolean;
    isTouched: boolean;
    entity: Entity[];
  };
  [TargetingOptionIds.emotions]: {
    isIncluded: boolean;
    isTouched: boolean;
    emotionsValues: EmotionTargetOption[];
  };
  [TargetingOptionIds.personas]: {
    isIncluded: boolean;
    isTouched: boolean;
    personasValues: string[];
  };
  categoriesDataObject: Record<string, MappedCategory>;
  categoriesSearchString: string;
};

export type Language = {
  id: string;
  language: string;
  iso: string;
};

export type Ssp = {
  key: string;
  value: string;
  id: string;
};

export type Dsp = {
  id: string;
  name: string;
  key: string;
  seatId: number;
};

export type Geo = {
  id: string;
  location: string;
  iso: string;
};

export const SspOptionTypes = {
  rulesBased: 'Rules Based',
  liveBid: 'Live Bid',
};

export const LiveBidSspValues = ['Xandr'];

export interface SSP {
  id: string;
  key: string;
  name: string;
}

export type Persona = {
  id: string;
  name: string;
  description: string;
  parentId: string | null;
  keywordGroups: string[] | null;
};

export type TrackingTags = {
  standard: {
    impression: string;
    viewability: string;
    click: string;
    vastClick: string;
    attrImpression: string;
    attrClick: string;
    attrConversion: string;
  };
  video: {
    start: string;
    pause: string;
    resume: string;
    firstQuartile: string;
    midPoint: string;
    thirdQuartile: string;
    complete: string;
    mute: string;
    unmute: string;
    fullscreen: string;
  };
};

export interface SegmentState extends CreateStepValues, AutomatedStepValues {
  currentBucket: ManualStepValues;
  buckets: ManualStepValues[];
  mode: SegmentMode;
  [TargetingOptionIds.sensitiveSubjects]: {
    isIncluded: boolean;
    isTouched: boolean;
    sensitiveSubjectsValues: string[];
  };
  segmentToCopyId: string | null;
  deletedSegmentId: string | null;
  isLoading: boolean;
  isCreated: boolean;
  isUpdating: boolean;
  isUpdated: boolean;
  isDeleting: boolean;
  isDeleted: boolean;
  id: string;
  performance: PerformanceMetrics;
  status: SegmentStatus | null;
  companyId: string;
  teamId: string;
  tags: TrackingTags;
  filters: {
    interval: IntervalFilter;
  };
  segmentReportLoading: boolean;
  reportSegment: ReportSegment;
}

export type SegmentResponse = {
  id: string;
  name: string;
  description: string;
  status: string;
};

export type SegmentGroup = {
  id: string;
  ssp: string;
  sspGroupId: string;
  groupName: string;
  price: number;
};

export type DomainList = {
  name: string;
  id: string;
};

export type EntitiesSuggestion = {
  keywords: {
    [key: string]: string;
  };
};

export type Prometheus = {
  id: string;
  name: string;
  type: string;
  value: number;
};

export type DailyMatched = {
  datetime: string;
  id: string;
  impressions: number;
  matched_urls: number;
  xandr_upload: number;
  pubmatic_response: number;
  equativ_response: number;
  xandr_response: number;
};

export type ReportSegment = {
  prometheus: Prometheus[];
  dailyMatched: DailyMatched[];
};

export type SegmentFormValues = {
  ssp: string | null;
  domainList: string | null;
  isIncluded: boolean;
  geo: string[];
  name: string;
  dsp: string | null;
  parentId: string | null;
  group: string | null;
  hideNameForPartner: boolean;
  dspSeatId: string | null;
  sspDealId: string | null;
  expiredDate: string | null;
  debugUntil: string | null;
  labels: string[];
  publicSegment: boolean;
};

export type MetricKey =
  | 'impressions'
  | 'matchedUrls'
  | 'xandrUpload'
  | 'pubmaticResponse'
  | 'equativResponse'
  | 'xandrResponse';
