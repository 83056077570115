import { Upload as UploadAntd, UploadProps } from 'antd';
import classNames from 'classnames';

import { nanoTwMerge } from 'services/twMerge';

const { Dragger } = UploadAntd;

export const Uploader = ({ className, ...rest }: UploadProps) => {
  return (
    <Dragger
      className={nanoTwMerge(classNames('nano-uploader', className))}
      showUploadList={false}
      {...rest}
    />
  );
};
