import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { call, put } from 'redux-saga/effects';

import { segmentsListApiSlice } from 'modules/segment/list/segmentsListApiSlice';

import { notifyError } from 'services/logService';

import { segmentsApi } from '../segmentApi';
import { deleteSegmentFailure, deleteSegmentSuccess } from '../segmentSlice';

export function* segmentDeleteSaga({ payload }: PayloadAction<{ id: string }>) {
  try {
    yield call(segmentsApi.deleteSegment, payload.id);
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
    yield put(deleteSegmentSuccess());
  } catch (e) {
    notifyError(e);
    yield put(
      deleteSegmentFailure({
        message: i18next.t('errors.segment.DeleteFailed'),
      }),
    );
  }
}
