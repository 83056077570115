import { Handle, NodeProps, Position, Node } from '@xyflow/react';

type SegmentFlowNodeProps = {
  id: string;
  name: string;
};

type SegmentFlowNode = Node<SegmentFlowNodeProps>;

export function SegmentFlowNode({ data }: NodeProps<SegmentFlowNode>) {
  return (
    <div
      className="px-4 py-2 shadow-md rounded-md bg-white bborder  border-nano-dark-purple border-opacity-25 bg-scale-impact-hover-gradient
      text-text-5"
    >
      <div className="flex">
        <div className="ml-2">
          <div className="text-base-xl font-bold">{data?.name}</div>
          {/*<div className="text-base-xl font-bold">{data?.id}</div>*/}
        </div>
      </div>

      <Handle type="source" position={Position.Right} className="ww-16 !bg-teal-500" />
    </div>
  );
}
