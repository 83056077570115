import * as d3 from 'd3';
import { HierarchyNode } from 'd3-hierarchy';
import { NodeId, OrgChart } from 'd3-org-chart';
import {
  ArrowDownFromLine,
  ArrowLeft,
  ArrowRightFromLine,
  Crosshair,
  Fullscreen,
  ListTree,
  Network,
  Shrink,
} from 'lucide-react';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Tooltip } from 'components/common';

import { IntentEstimateDiagramResponse, OrgAllNodes, OrgNodeData } from '../types';
import { NodeButton } from './nodes/NodeButton';
import { NodeContent } from './nodes/NodeContent';
import { makeOrgNodeData } from './utils';

type OrgChartProps = {
  onClick: (node: HierarchyNode<OrgAllNodes>) => void;
  dataFromApi: IntentEstimateDiagramResponse;
  isPanelVisible: boolean;
  segmentId: string;
};

const renderNodeContent = (node: HierarchyNode<OrgNodeData>) => {
  return renderToString(<NodeContent node={node} />);
};

const renderNodeButton = (node: HierarchyNode<OrgNodeData>, orientation: string) => {
  return renderToString(<NodeButton node={node} orientation={orientation} />);
};

export const OrgChartComponent = memo(
  ({ onClick, dataFromApi, isPanelVisible, segmentId }: OrgChartProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const chartRef = useRef(new OrgChart());
    const d3Container = useRef(null);
    const [activeNodeId, setActiveNodeId] = useState<string>('');
    const [anyNodeSelected, setAnyNodeSelected] = useState<boolean>(false);

    let orientationT = 'left';

    const nodeClick = useCallback(
      (node: HierarchyNode<OrgAllNodes>) => {
        if (node.id) {
          chartRef.current.clearHighlighting();

          if (isPanelVisible && anyNodeSelected && node.id === activeNodeId) {
            chartRef.current.clearHighlighting();
            setAnyNodeSelected(false);
          } else {
            chartRef.current
              .setHighlighted(node.id as NodeId)
              .setUpToTheRootHighlighted(node.id as NodeId)
              .render();

            setActiveNodeId(node.id);
            setAnyNodeSelected(true);
          }
        }

        onClick(node);
      },
      [onClick, isPanelVisible, activeNodeId, anyNodeSelected],
    );

    const layoutTop = () => {
      // I don't want to make one method!
      orientationT = 'top';
      chartRef.current
        .layout('top')
        .buttonContent((params) =>
          renderNodeButton(params.node as HierarchyNode<OrgNodeData>, 'top'),
        )
        .render()
        .fit();
    };

    const layoutLeft = () => {
      orientationT = 'left';
      chartRef.current
        .layout('left')
        .buttonContent((params) =>
          renderNodeButton(params.node as HierarchyNode<OrgNodeData>, 'left'),
        )
        .render()
        .fit();
    };

    const fit = useCallback(() => {
      chartRef.current.fit();
    }, [chartRef]);

    const stacked = useCallback(
      (isStacked: boolean) => {
        chartRef.current.compact(isStacked).render().fit();
      },
      [chartRef],
    );

    const fullscreen = useCallback(() => {
      if (d3Container.current) {
        chartRef.current.fullscreen();
      }
    }, []);

    chartRef.current.onNodeClick((node) => nodeClick(node as HierarchyNode<OrgAllNodes>));

    useEffect(() => {
      const data = makeOrgNodeData(dataFromApi);

      if (d3Container.current && data.length > 0) {
        chartRef.current
          .container(d3Container.current)
          .data(data)
          .layout('left')
          .compact(false)
          .nodeWidth((dataItem) => (dataItem as HierarchyNode<OrgNodeData>).data.width)
          .nodeHeight((dataItem) => (dataItem as HierarchyNode<OrgNodeData>).data.height)
          .nodeId((dataItem) => (dataItem as OrgNodeData).id)
          .parentNodeId((dataItem) => (dataItem as OrgNodeData).parentId)
          .nodeContent((node) => renderNodeContent(node as HierarchyNode<OrgNodeData>))
          .buttonContent((params) =>
            renderNodeButton(params.node as HierarchyNode<OrgNodeData>, orientationT),
          )
          .linkUpdate(() => {
            // d3.selectAll('path.link').style('stroke', '#c49cc8');

            d3.selectAll('path.link').each(function () {
              d3.select(this)
                .attr('stroke', (d) =>
                  (d as HierarchyNode<OrgNodeData>).data._upToTheRootHighlighted
                    ? '#5C2678'
                    : '#c49cc8',
                )
                .attr('stroke-width', (d) =>
                  (d as HierarchyNode<OrgNodeData>).data._upToTheRootHighlighted ? 5 : 1,
                );
            });
          })

          .nodeUpdate(() => {
            // Prevent default highlighting of nodes
            d3.selectAll(this).select('.node-rect').attr('stroke', 'none');
          })
          .svgWidth(window.innerWidth)
          .svgHeight(window.innerHeight)
          .render()
          .fit();
      }
    }, [dataFromApi, orientationT]);

    useEffect(() => {
      if (!isPanelVisible) {
        chartRef.current.clearHighlighting();
      }
    }, [isPanelVisible]);

    return (
      <div className="h-full w-full">
        <div className="flex flex-col gap-2 fixed left-[130px] top-[10px]">
          <div className="flex flex-row gap-2">
            <Tooltip placement="top" title={t('intentEstimate.backButtonTooltip')}>
              <Button
                variant="outline"
                className=" flex flex-row gap-2 border-nano-pca-green"
                onClick={() => {
                  navigate(`/segment/${segmentId}`);
                }}
              >
                <ArrowLeft /> <p> {t('intentEstimate.intentEstimateText')}</p>
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="flex flex-col gap-2 fixed left-[130px] bottom-[10px]">
          <div className="flex flex-row gap-2">
            <Tooltip placement="top" title={t('intentEstimate.chartLayoutButtons.left')}>
              <Button variant="outline" onClick={layoutLeft}>
                <ArrowRightFromLine />
              </Button>
            </Tooltip>

            <Tooltip placement="top" title={t('intentEstimate.chartLayoutButtons.top')}>
              <Button variant="outline" onClick={layoutTop}>
                <ArrowDownFromLine />
              </Button>
            </Tooltip>
          </div>

          <div className="flex flex-row gap-2">
            <Tooltip placement="top" title={t('intentEstimate.chartLayoutButtons.fit')}>
              <Button variant="outline" onClick={fit}>
                <Crosshair />
              </Button>
            </Tooltip>

            <Tooltip placement="top" title={t('intentEstimate.chartLayoutButtons.stacked')}>
              <Button variant="outline" onClick={() => stacked(true)}>
                <ListTree />
              </Button>
            </Tooltip>
            <Tooltip placement="top" title={t('intentEstimate.chartLayoutButtons.tree')}>
              <Button variant="outline" onClick={() => stacked(false)}>
                <Network />
              </Button>
            </Tooltip>
          </div>

          <div className="flex flex-row gap-2">
            <Tooltip placement="top" title={t('intentEstimate.chartLayoutButtons.collapse')}>
              <Button variant="outline" onClick={() => chartRef.current.collapseAll().fit()}>
                <Shrink />
              </Button>
            </Tooltip>

            {/*<Tooltip placement="top" title={t('intentEstimate.chartLayoutButtons.expand')}>*/}
            {/*  <Button variant="outline" onClick={() => chartRef.current.expandAll().fit()}>*/}
            {/*    <Expand />*/}
            {/*  </Button>*/}
            {/*</Tooltip>*/}

            <Tooltip placement="top" title={t('intentEstimate.chartLayoutButtons.fullscreen')}>
              <Button variant="outline" onClick={fullscreen}>
                <Fullscreen />
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="h-full w-full" ref={d3Container}></div>
      </div>
    );
  },
);

OrgChartComponent.displayName = 'OrgChartComponent';
