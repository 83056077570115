import React from 'react';

import { OrgIntentGroupNode } from '../../types';

export const IntentGroupNodeContent = ({ node }: { node: OrgIntentGroupNode }) => {
  const active: boolean = node._highlighted || node._upToTheRootHighlighted;

  return (
    <div className="flex flex-col justify-center items-center h-full w-full rounded-2xl">
      <div className="bg-transparent h-full w-full rounded-2xl ">
        <div
          className={`rounded-2xl p-4 bg-primary-gradient w-full h-full flex flex-row justify-start items-center ${
            active ? 'border-4 border-secondary-darkPurple' : ''
          }`}
        >
          <p className="text-base-xl whitespace-normal break-words w-full flex items-center justify-center text-secondary-white">
            {node.name}
          </p>
        </div>
      </div>
    </div>
  );
};
