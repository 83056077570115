import { memo, useEffect, useState } from 'react';

export const SentimentProgress = memo(({ sentimentValue }: { sentimentValue: number }) => {
  const [isNeutral, setIsNeutral] = useState<boolean>(false);
  const [positiveSentiment, setPositiveSentiment] = useState<number>(0);
  const [negativeSentiment, setNegativeSentiment] = useState<number>(0);

  useEffect(() => {
    setPositiveSentiment(sentimentValue > 0 ? +sentimentValue : 0); // string to int ? :D
    setNegativeSentiment(sentimentValue < 0 ? sentimentValue * -1 : 0);

    setIsNeutral(sentimentValue >= -0.05 && sentimentValue <= 0.05);

    if (isNeutral) {
      setNegativeSentiment(0.05);
      setPositiveSentiment(0.05);
    }
  }, [isNeutral, sentimentValue]);

  return (
    <>
      <div className="sentiment-progress-wrapper">
        <div className={`negative-sentiment-wrapper ${isNeutral ? 'grayed-sentiment' : ''} `}>
          <progress value={negativeSentiment} max="1"></progress>
        </div>

        <div className={`positive-sentiment-wrapper ${isNeutral ? 'grayed-sentiment' : ''}`}>
          <progress value={positiveSentiment} max="1"></progress>
        </div>
      </div>
    </>
  );
});

SentimentProgress.displayName = 'SentimentProgress';
