import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from '../../services/logService';
import {
  intentEstimateOrgFailure,
  intentEstimateOrgStart,
  intentEstimateOrgSuccess,
} from './IntentEstimateSlice';
import { intentEstimateDiagramApi } from './intentEstimateFlowDiagramApi';
import { getIntentEstimateOrgState } from './intentEstimateSelectors';
import {
  IntentEstimateDiagramRequest,
  IntentEstimateDiagramResponse,
  IntentEstimateOrgState,
} from './types';

function* intentEstimateGetSaga() {
  try {
    const intentEstimateOrgState: IntentEstimateOrgState = yield select(getIntentEstimateOrgState);

    const requestData: IntentEstimateDiagramRequest = {
      segmentId: intentEstimateOrgState.requestParamSegmentId,
    };

    const response: IntentEstimateDiagramResponse = yield call(
      intentEstimateDiagramApi.getDiagramData,
      requestData,
    );

    yield put(intentEstimateOrgSuccess(response));
  } catch (e) {
    notifyError(e);

    yield put(
      intentEstimateOrgFailure({ message: i18next.t('adminPanel.distanceChecker.errors.failed') }),
    );
  }
}

export function* intentEstimateOrgSagas() {
  yield all([takeLatest(intentEstimateOrgStart.type, intentEstimateGetSaga)]);
}
