import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { PixelItem, PixelState } from '../constants';
import { pixelApi } from '../pixelApi';
import { pixelListApiSlice } from '../pixelListApiSlice';
import { getPixel } from '../pixelSelectors';
import { getPixelByIdFailure, getPixelByIdSuccess } from '../pixelSlice';

export function* getPixelByIdSaga() {
  try {
    const pixelState: PixelState = yield select(getPixel);

    const pixelResponse: PixelItem = yield call(pixelApi.getPixelById, pixelState.id);

    yield put(pixelListApiSlice.util.invalidateTags(['Pixel']));
    yield put(getPixelByIdSuccess(pixelResponse));
  } catch (e) {
    notifyError(e);

    yield put(
      getPixelByIdFailure({
        message: i18next.t('pages.pixel.errors.pixelFetchFailed'),
      }),
    );
  }
}
