import React from 'react';

import { OrgAllNodes } from '../../types';
import { CategoryPanel } from './CategoryPanel';
import { CategoryRootPanel } from './CategoryRootPanel';
import { DomainPanel } from './DomainPanel';
import { DomainRootPanel } from './DomainRootPanel';
import { IntentGroupPanel } from './IntentGroupPanel';
import { SegmentPanel } from './SegmentPanel';
import { SentimentPanel } from './SentimentPanel';

export type PanelContentProps = {
  node: OrgAllNodes | null;
};

export const PanelContent = ({ node }: PanelContentProps) => {
  if (!node) {
    return null;
  }

  let Panel = SegmentPanel;

  if (node.type === 'CategoryRoot') {
    Panel = CategoryRootPanel;
  }
  if (node.type === 'Category') {
    Panel = CategoryPanel;
  }
  if (node.type === 'Sentiment') {
    Panel = SentimentPanel;
  }
  if (node.type === 'DomainRoot') {
    Panel = DomainRootPanel;
  }
  if (node.type === 'Domain') {
    Panel = DomainPanel;
  }
  if (node.type === 'IntentGroup') {
    Panel = IntentGroupPanel;
  }

  return (
    <>
      <div className="bg-secondary-white h-full w-full">
        <div className="p-6">
          <Panel node={node} />
        </div>
      </div>
    </>
  );
};
