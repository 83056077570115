import {
  DomainMap,
  IntentEstimateDiagramResponse,
  IntentGroup,
  OrgCategoryNode,
  OrgCategoryNodeUrls,
  OrgCategoryRootNode,
  OrgDomainNode,
  OrgDomainRootNode,
  OrgIntentGroupNode,
  OrgNodeData,
  OrgSegmentNode,
  OrgSentimentNode,
} from '../types';

export const makeOrgNodeData = (data: IntentEstimateDiagramResponse) => {
  const nodes: OrgNodeData[] = [];
  const intentGroups: IntentGroup[] = data.intentClusters[0].intentGroups; // @todo Make sure this exists ustvari boli me :D

  const rootNodeId = data.segmentId;
  const rootNode: OrgSegmentNode = {
    id: rootNodeId,
    parentId: '',
    name: 'Segment ' + data.segmentName,
    type: 'Segment',
    data: data,
    width: 200,
    height: 100,
    childrenCount: intentGroups.length,
    _highlighted: false,
    _upToTheRootHighlighted: false,
  };

  nodes.push(rootNode);

  for (const [index, intentGroup] of intentGroups.entries()) {
    const intentGroupNodeId = rootNodeId + '-intentGroup-' + index;
    const intentGroupNode: OrgIntentGroupNode = {
      id: intentGroupNodeId,
      parentId: rootNodeId,
      name: intentGroup.name,
      type: 'IntentGroup',
      intentGroup: intentGroup,
      width: 200,
      height: 100,
      childrenCount: 3,
      _highlighted: false,
      _upToTheRootHighlighted: false,
    };

    nodes.push(intentGroupNode);

    // Add one root category node to each intent group
    const categoryRootNodeId = intentGroupNodeId + '-categoryRoot-';
    const categoryRootNode: OrgCategoryRootNode = {
      id: categoryRootNodeId,
      parentId: intentGroupNodeId,
      name: 'Categories',
      type: 'CategoryRoot',
      categoryDistribution: intentGroup.categoryDistribution,
      width: 200,
      height: 100,
      childrenCount: intentGroup.categoryDistribution.distribution.length,
      _highlighted: false,
      _upToTheRootHighlighted: false,
    };

    nodes.push(categoryRootNode);

    // Categories as nodes
    for (const [index, category] of intentGroup.categoryDistribution.distribution.entries()) {
      const actualCategoryNode: OrgCategoryNode = {
        id: categoryRootNodeId + '-category-' + index,
        parentId: categoryRootNodeId,
        name: category.name,
        percentage: category.percentage,
        type: 'Category',
        categoryDistribution: intentGroup.categoryDistribution,
        width: 300,
        height: 100,
        urls: getUrlsForCategory(category.name, intentGroup.domainMap),
        childrenCount: 0,
        _highlighted: false,
        _upToTheRootHighlighted: false,
      };

      nodes.push(actualCategoryNode);
    }

    // Domains
    const domainRootNodeId = intentGroupNodeId + '-domainRoot-';
    const domainRootNode: OrgDomainRootNode = {
      id: domainRootNodeId,
      parentId: intentGroupNodeId,
      name: 'Domains',
      type: 'DomainRoot',
      domainMap: intentGroup.domainMap,
      width: 200,
      height: 100,
      childrenCount: Object.keys(intentGroup.domainMap).length,
      _highlighted: false,
      _upToTheRootHighlighted: false,
    };

    nodes.push(domainRootNode);

    Object.entries(intentGroup.domainMap).forEach(([domainName, domainUrlDataObj], index) => {
      const domainNode: OrgDomainNode = {
        id: intentGroupNodeId + '-domain-' + index,
        parentId: domainRootNodeId,
        name: domainName,
        type: 'Domain',
        domainUrlData: domainUrlDataObj,
        width: 200,
        height: 100,
        childrenCount: 0,
        _highlighted: false,
        _upToTheRootHighlighted: false,
      };

      nodes.push(domainNode);
    });

    // Sentiment is child of Intent Group node
    const sentimentNodeId = intentGroupNodeId + '-sentiment-';
    const sentimentNode: OrgSentimentNode = {
      id: sentimentNodeId,
      parentId: intentGroupNodeId,
      name: 'Sentiment',
      type: 'Sentiment',
      sentimentDistribution: intentGroup.sentimentDistribution,
      width: 200,
      height: 100,
      childrenCount: 0,
      _highlighted: false,
      _upToTheRootHighlighted: false,
    };

    nodes.push(sentimentNode);
  }

  return nodes;
};

const getUrlsForCategory = (categoryName: string, domainMap: DomainMap) => {
  const urls: OrgCategoryNodeUrls[] = [];

  for (const [, domainUrlObject] of Object.entries(domainMap)) {
    for (const urlOjb of domainUrlObject) {
      if (
        urlOjb.categories.filter((catObj) => {
          return catObj.name === categoryName;
        }).length > 0
      ) {
        urls.push({
          url: urlOjb.url,
          sentiment: urlOjb.sentiment,
          language: urlOjb.language,
          similarity: urlOjb.similarity,
        });
      }
    }
  }

  return [...urls.sort((a, b) => b.similarity - a.similarity)];
};
