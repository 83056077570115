import { all } from 'redux-saga/effects';

import { adminPanelSaga } from 'modules/adminPanel/adminPanelSaga';
import { authFlowSagas } from 'modules/auth/authFlowSagas';
import { chartsSagas } from 'modules/charts/chartsSagas';
import { labelsSagas } from 'modules/labels/labelsSagas';
import { nanoBotSagas } from 'modules/nanoBot/nanoBotSagas';
import { notificationSagas } from 'modules/notifications';
import { emptySegmentSagas } from 'modules/segment/emptySegment/emptySegmentSagas';
import { segmentSagas } from 'modules/segment/segmentSagas';

import { advertiserSagas } from '../modules/advertiser/advertiserSagas';
import { intentEstimateOrgSagas } from '../modules/intentEstimateFlowDiagram/IntentEstimateSagas';
import { pixelSagas } from '../modules/pixel/pixelSagas';
import { expiredDateSagas } from '../modules/segment/sagas/expiredDateSagas';
import { tagWrappingToolSagas } from '../modules/tagWrappingTool/tagWrappingToolSagas';

export function* rootSaga() {
  yield all([
    authFlowSagas(),
    nanoBotSagas(),
    labelsSagas(),
    expiredDateSagas(),
    segmentSagas(),
    advertiserSagas(),
    chartsSagas(),
    notificationSagas(),
    emptySegmentSagas(),
    adminPanelSaga(),
    tagWrappingToolSagas(),
    intentEstimateOrgSagas(),
    pixelSagas(),
  ]);
}
