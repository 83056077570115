import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'services/api';

export const nanoApi = createApi({
  reducerPath: 'nanoApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Segments', 'SegmentApiData', 'Advertiser', 'Pixel'],
  endpoints: () => ({}),
});
