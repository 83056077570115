import { Handle, Position, Node, NodeProps } from '@xyflow/react';

type IntentGroupFlowNodeProps = {
  intentGroupName: string;
  keywords: string[];
};

type IntentGroupFlowNode = Node<IntentGroupFlowNodeProps>;

export const IntentGroupFlowNode = ({ data }: NodeProps<IntentGroupFlowNode>) => {
  return (
    <div className="cursor-pointer p-4 rounded-xl border-opacity-50 bg-nanobot-gradient w-[300px] ">
      <p>{data.intentGroupName}</p>

      <div className="flex flex-col ggap-2 divide-y divide-nano-light-purple divide-opacity-25">
        {data.keywords.map((sentence, index) => (
          <div key={index} className="py-2">
            <p className="text-base-s font-light">{sentence}</p>
          </div>
        ))}
      </div>

      <Handle type="target" position={Position.Left} className="ww-16 !bg-teal-500" />
      <Handle type="source" position={Position.Right} className="ww-16 !bg-teal-500" />
    </div>
  );
};
