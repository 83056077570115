import Dagre from '@dagrejs/dagre';
import {
  Background,
  Controls,
  Edge,
  Node,
  Panel,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import ELK, { ElkNode } from 'elkjs/lib/elk.bundled.js';
import React, { useCallback, useMemo, useRef } from 'react';

import { Button } from '../../components/common';
import { createNodesAndEdges, mockData, nodeTypes } from './nodes/nodes-edges';

type LayoutOptions = {
  direction: string;
  align?: string;
};

const elk = new ELK();

const getLayoutElementsWithDagre = (nodes: Node[], edges: Edge[], options: LayoutOptions) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

  g.setGraph({ rankdir: options.direction });

  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) =>
    g.setNode(node.id, {
      ...node,
      width: node.measured?.width ?? 0,
      height: node.measured?.height ?? 0,
    }),
  );

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const position = g.node(node.id);
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      const x = position.x - (node.measured?.width ?? 0) / 2;
      const y = position.y - (node.measured?.height ?? 0) / 2;

      return { ...node, position: { x, y } };
    }),
    edges,
  };
};

const useLayoutedElementsELK = () => {
  const { getNodes, setNodes, getEdges, fitView } = useReactFlow();
  const defaultOptions = useMemo(() => {
    return {
      'elk.algorithm': 'layered',
      // 'elk.layered.spacing.nodeNodeBetweenLayers': 100,
      // 'elk.spacing.nodeNode': 80,
    };
  }, []);

  const getLayoutedElements = useCallback(
    (options: { [key: string]: string | number }) => {
      const layoutOptions = { ...defaultOptions, ...options };
      const graph = {
        id: 'root',
        layoutOptions: layoutOptions,
        children: getNodes().map((node) => ({
          ...node,
          width: node.measured?.width,
          height: node.measured?.height,
        })),
        edges: getEdges(),
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      elk.layout(graph).then(({ children }) => {
        // By mutating the children in-place we saves ourselves from creating a
        // needless copy of the nodes array.
        children?.forEach((node: ElkNode) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          node.position = { x: node.x, y: node.y };
          node.edges?.forEach((edge) => edge.sources);
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setNodes(children);

        window.requestAnimationFrame(() => {
          fitView();
        });
      });
    },
    [defaultOptions, fitView, getEdges, getNodes, setNodes],
  );

  return { getLayoutedElements };
};

export const IntentEstimateFlowDiagram = () => {
  const reactFlowWrapper = useRef(null);

  const { nodes: initialNodes, edges: initialEdges } = createNodesAndEdges(mockData);

  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  // const [visibleChildren, setVisibleChildren] = useState<Record<string, boolean>>({});

  const onLayout = useCallback(
    (direction: string, align: string) => {
      const layouted = getLayoutElementsWithDagre(nodes, edges, {
        direction: direction,
        align: align,
      });

      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);

      window.requestAnimationFrame(() => {
        fitView();
      });
    },
    [fitView, setNodes, setEdges, nodes, edges],
  );

  const { getLayoutedElements } = useLayoutedElementsELK();

  // const handleNodeClick = (event: React.MouseEvent, node: Node) => {
  //   if (visibleChildren[node.id]) {
  //     removeChildNodes(node);
  //   } else {
  //     addNode(node);
  //   }
  // };

  // const addNode = (clickedNode: Node) => {
  //
  //   const index = clickedNode.id;
  //
  //   const sentencesGroupDataNode: Node = {
  //     id: index + 'n1',
  //     position: { x: clickedNode.position.x + 150, y: clickedNode.position.y - 50 },
  //     data: { sentences: ['s1', 's2'] },
  //     type: 'sentencesFlowNode',
  //   };
  //
  //   const sentencesGroupDataNode2: Node = {
  //     id: index + 'n2',
  //     position: { x: clickedNode.position.x + 350, y: clickedNode.position.y - 50 },
  //     data: { sentences: ['s1', 's2'] },
  //     type: 'sentencesFlowNode',
  //   };
  //
  //   const newNodes = [sentencesGroupDataNode, sentencesGroupDataNode2];
  //
  //   setNodes((nds) => [...nds, ...newNodes]);
  //
  //   const edge: Edge = {
  //     id: 'e' + index + index + 'n1',
  //     source: index,
  //     target: index + 'n1',
  //     animated: true,
  //   };
  //   const edge2: Edge = {
  //     id: 'e2' + index + index + 'n2',
  //     source: index,
  //     target: index + 'n2',
  //     animated: true,
  //   };
  //
  //   const newEdges = [edge, edge2];
  //
  //   setEdges((eds) => [...eds, ...newEdges]);
  //
  //
  //   // Update visibility state
  //   setVisibleChildren((prev) => ({
  //     ...prev,
  //     [clickedNode.id]: true, // Mark the parent as having visible children
  //   }));
  //
  //
  //   // onLayout('LR', 'DL');
  //
  // };
  //
  // const getChildrenNodeIds = (parentNode: Node) => {
  //   return edges
  //     .filter((edge: Edge) => {
  //       return edge.source === parentNode.id;
  //     })
  //     .map((edge) => edge.target);
  // };
  //
  // const removeChildNodes = (clickedNode: Node) => {
  //   const childIds = getChildrenNodeIds(clickedNode);
  //
  //   setNodes((nds) => nds.filter((node) => !childIds.includes(node.id)));
  //   setEdges((eds) => eds.filter((edge) => !childIds.includes(edge.target)));
  //
  //   setVisibleChildren((prev) => ({
  //     ...prev,
  //     [clickedNode.id]: false, // Mark the parent as having visible children
  //   }));
  // };

  return (
    <div className="bborder w-full h-full" ref={reactFlowWrapper}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        fitView
        // onNodeClick={handleNodeClick}
      >
        <Panel position={'top-left'}>
          <div className="flex flex-row gap-2">
            <Button variant="outline" onClick={() => onLayout('LR', 'DL')}>
              Vertical Dagre
            </Button>

            <Button variant="outline" onClick={() => onLayout('TB', 'DL')}>
              Horizontal Dagre
            </Button>

            <Button
              variant="outline"
              onClick={() =>
                getLayoutedElements({
                  'elk.algorithm': 'layered',
                  'elk.direction': 'DOWN',
                  'org.eclipse.elk.alignment': 'BOTTOM',
                })
              }
            >
              Vertical ELK
            </Button>

            <Button
              variant="outline"
              onClick={() =>
                getLayoutedElements({
                  'elk.algorithm': 'layered',
                  'elk.direction': 'RIGHT',
                })
              }
            >
              Horizontal ELK
            </Button>

            <Button
              variant="outline"
              onClick={() =>
                getLayoutedElements({
                  'elk.algorithm': 'org.eclipse.elk.radial',
                  'org.eclipse.elk.spacing.nodeNode': 1,
                })
              }
            >
              Radial ELK
            </Button>

            <Button
              variant="outline"
              onClick={() =>
                getLayoutedElements({
                  'elk.algorithm': 'org.eclipse.elk.force',
                })
              }
            >
              Force ELK
            </Button>

            <Button
              variant="outline"
              onClick={() =>
                getLayoutedElements({
                  'elk.algorithm': 'org.eclipse.elk.stress',
                  'org.eclipse.elk.stress.desiredEdgeLength': 300,
                })
              }
            >
              Stress ELK good
            </Button>

            <Button
              variant="outline"
              onClick={() =>
                getLayoutedElements({
                  'elk.algorithm': 'org.eclipse.elk.mrtree',
                })
              }
            >
              Test ELK good
            </Button>
            <Button
              variant="outline"
              onClick={() =>
                getLayoutedElements({
                  'elk.algorithm': 'org.eclipse.elk.alg.libavoid',
                })
              }
            >
              Test ELK good
            </Button>
          </div>
        </Panel>
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};
