export const PIXEL_URL = 'pixel/';

export interface PixelFormData {
  advertiserId: string | null;
  companyId: string | null;
  name: string;
  piggyBacks: string[];
  teamId: string | null;
  type: string | null;
  userId: string | null;
}

export interface PixelState extends PixelFormData {
  isLoading: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isUpdating: boolean;
  pixelToDeleteId: string;
  errorMessage: string | null;
  id: string;
}

export interface PixelItem extends PixelFormData {
  id: string;
}
