import { Handle, Position } from '@xyflow/react';

export const UrlsFlowNode = () => {
  return (
    <div className="border p-2 rounded">
      {' '}
      Domains and urls
      <div className="text-base-s"> Url 1 </div>
      <div className="text-base-s"> Url 2 </div>
      <div className="text-base-s"> Url 3 </div>
      <Handle type="target" position={Position.Left} className="ww-16 !bg-teal-500" />
    </div>
  );
};
