import { api } from 'services/api';

import { PIXEL_URL, PixelFormData, PixelItem } from './constants';

const getPixels = () => {
  return api.get<PixelItem[]>(PIXEL_URL);
};

const getPixelById = (id: string) => {
  return api.get<PixelItem>(`${PIXEL_URL}${id}`);
};

const savePixel = (data: PixelFormData) => {
  return api.post<PixelItem>(PIXEL_URL, data);
};

const updatePixel = (id: string, data: PixelFormData) => {
  return api.put<PixelItem>(`${PIXEL_URL}${id}`, data);
};

const deletePixel = (id: string) => {
  return api.delete(`${PIXEL_URL}${id}`);
};

export const pixelApi = {
  getPixels,
  getPixelById,
  savePixel,
  updatePixel,
  deletePixel,
};
