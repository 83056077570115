import { Handle, Position, Node, NodeProps } from '@xyflow/react';

type SentencesFlowNodeProps = {
  sentences: string[];
};

type SentencesFlowNode = Node<SentencesFlowNodeProps>;

export const SentencesFlowNode = ({ data }: NodeProps<SentencesFlowNode>) => {
  return (
    <>
      <div className="border h-full p-4 border-nano-light-purple rounded-xl bg-secondary-white">
        <div className="h-full flex flex-col gap-2 ">
          <p className="text-base-l">Intent Prompts</p>

          <div className="">
            {data.sentences.map((sentence, index) => (
              <p className="text-base-s font-light" key={index}>
                {sentence}
              </p>
            ))}
          </div>
        </div>
        <div className=""></div>

        <Handle type="source" position={Position.Right} className="border" />
        <Handle type="target" position={Position.Left} className="border" />
      </div>
    </>
  );
};
