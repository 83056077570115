import { nanoApi } from '../../redux/rootApiSlice';
import { PixelItem, PIXEL_URL } from './constants';

export const pixelListApiSlice = nanoApi.injectEndpoints({
  endpoints: (build) => ({
    pixelList: build.query<PixelItem[], null>({
      query: () => ({ method: 'GET', url: PIXEL_URL }),
      providesTags: ['Pixel'],
    }),
  }),
});

export const { usePixelListQuery } = pixelListApiSlice;
