import { api } from 'services/api';

import {
  ADVERTISER_URL,
  AdvertiserFormData,
  AdvertiserItem,
  AdvertiserResponse,
} from './constants';

const getAdvertisers = () => {
  return api.get<AdvertiserItem[]>(ADVERTISER_URL);
};

const getAdvertiserById = (id: string) => {
  return api.get<AdvertiserItem>(`${ADVERTISER_URL}/${id}`);
};

const saveAdvertiser = (data: AdvertiserFormData) => {
  return api.post<AdvertiserResponse>(ADVERTISER_URL, data);
};

const updateAdvertiser = (id: string, data: AdvertiserFormData) => {
  return api.put<AdvertiserResponse>(`${ADVERTISER_URL}/${id}`, data);
};

const deleteAdvertiser = (id: string) => {
  return api.delete(`${ADVERTISER_URL}/${id}`);
};

export const advertiserApi = {
  getAdvertisers,
  getAdvertiserById,
  saveAdvertiser,
  updateAdvertiser,
  deleteAdvertiser,
};
