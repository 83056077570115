import { AppState } from 'redux/store/store';

export const getAdvertiser = ({ advertiser }: AppState) => advertiser;

export const getIsAdvertiserLoading = (state: AppState) => getAdvertiser(state).isLoading;

export const getAdvertiserToDeleteId = (state: AppState) =>
  getAdvertiser(state).advertiserToDeleteId;

export const getAdvertiserId = (state: AppState) => getAdvertiser(state).id;
