import React from 'react';

import { OrgDomainNode } from '../../types';

export const DomainNodeContent = ({ node }: { node: OrgDomainNode }) => {
  const active: boolean = node._highlighted || node._upToTheRootHighlighted;

  return (
    <div className="flex flex-col justify-center items-center h-full w-full rounded-2xl">
      <div className="bg-secondary-white h-full w-full rounded-2xl ">
        <div
          className={`rounded-2xl p-4 bg-nano-pca-teal bg-opacity-50 w-full h-full flex flex-row justify-start items-center ${
            active
              ? 'border-4 border-secondary-darkPurple'
              : 'border-4 border-nano-pca-teal border-opacity-5'
          }`}
        >
          <p className="text-base-l whitespace-normal break-words w-full">{node.name}</p>
        </div>
      </div>
    </div>
  );
};
