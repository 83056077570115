import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IntentEstimateDiagramRequest,
  IntentEstimateDiagramResponse,
  IntentEstimateOrgState,
} from './types';

export const INTENT_ESTIMATE_ORG_SLICE_NAME = 'intentEstimateSlice';

const initialState: IntentEstimateOrgState = {
  isLoading: false,
  failMessage: '',
  requestParamSegmentId: '',
  data: null,
};

export const intentEstimateOrgSlice = createSlice({
  name: INTENT_ESTIMATE_ORG_SLICE_NAME,
  initialState,
  reducers: {
    intentEstimateOrgStart: (
      state: IntentEstimateOrgState,
      { payload }: PayloadAction<IntentEstimateDiagramRequest>,
    ) => {
      state.isLoading = true;
      state.requestParamSegmentId = payload.segmentId;
    },
    intentEstimateOrgSuccess: (
      state: IntentEstimateOrgState,
      { payload }: PayloadAction<IntentEstimateDiagramResponse>,
    ) => {
      return {
        ...state,
        ...{ data: payload },
        ...{ isLoading: false },
      };
    },
    intentEstimateOrgFailure: (
      state: IntentEstimateOrgState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.failMessage = payload.message;
    },
  },
});

export const { intentEstimateOrgStart, intentEstimateOrgSuccess, intentEstimateOrgFailure } =
  intentEstimateOrgSlice.actions;

export const intentEstimateSliceReducer = {
  intentEstimateOrg: intentEstimateOrgSlice.reducer,
};
