/* eslint-disable camelcase */
import { AI_ASSISTANT_BRIEF_UPLOAD, AI_ASSISTANT_URL } from 'modules/nanoBot/constants';
import { NanoBotData, NanoBotResponse } from 'modules/nanoBot/types';

import { api } from 'services/api';

const sendMessage = (data: NanoBotData) => {
  const { userMessages: user_messages, message } = data;

  return api.post<NanoBotResponse>(AI_ASSISTANT_URL, { user_messages, message });
};

const nanoBotBriefUpload = (data: FormData) => {
  return api.post<NanoBotResponse>(AI_ASSISTANT_BRIEF_UPLOAD, data);
};

export const nanoBotApi = {
  sendMessage,
  nanoBotBriefUpload,
};
