import classNames from 'classnames';
import { Trash } from 'feather-icons-react';
import { Minus, UploadIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IconButton, Text } from 'components/common';
import { Uploader } from 'components/common/Uploader';
import { DocIcon, AutomatedPdfIcon, DocxIcon } from 'components/common/icons';
import { NanoBotImg } from 'components/common/nanoBot';

import { getBriefUploadDataNanoBot } from 'modules/nanoBot/nanoBotSlice';
import { useBriefOutlining } from 'modules/segment/segmentForms/AutomatedStepForm/useBriefOutlining';
import {
  DOC_FILE_MIME_TYPE,
  DOCX_FILE_MIME_TYPE,
  PDF_FILE_MIME_TYPE,
  PPT_FILE_MIME_TYPE,
  PPTX_FILE_MIME_TYPE,
  XLSX_FILE_MIME_TYPE,
} from 'modules/segment/segmentForms/constants';

export const NanoBotHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { handleFileUpload, uploadedFile, fileFormData, resetUploadedFile } = useBriefOutlining();
  const accept = `${DOC_FILE_MIME_TYPE}, ${DOCX_FILE_MIME_TYPE}, ${PDF_FILE_MIME_TYPE}, ${XLSX_FILE_MIME_TYPE}, ${PPT_FILE_MIME_TYPE}, ${PPTX_FILE_MIME_TYPE}`;

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (fileFormData) {
      dispatch(getBriefUploadDataNanoBot(fileFormData));
    }
  }, [fileFormData, dispatch]);

  return (
    <header className="flex items-center justify-between px-[24px] py-[16px] bg-primary-dark-purple-120">
      {/* Left Section */}
      <div className="flex items-center">
        <div className="mr-[10px]">
          <NanoBotImg className="h-[44px] w-[44px]" />
        </div>
        <div>
          <div className="text-text-5 text-lg font-semibold">{t('nanoBot.title')}</div>
          <div className="flex items-center mt-1">
            <div className="mr-[5px] h-[8px] w-[8px] rounded-full bg-secondary-teal" />
            <Text className="text-text-20">{t('nanoBot.status')}</Text>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div
        className={classNames(
          'flex items-center transition-all duration-300 overflow-hidden bg-primary-light-purple-20 rounded-full h-[40px]',
          {
            'w-[240px]': isExpanded || uploadedFile,
            'w-[80px]': !isExpanded && !uploadedFile,
          },
        )}
      >
        {uploadedFile ? (
          <div className="flex items-center h-[44px] px-[12px] w-full bg-primary-dark-purple text-white rounded-full">
            <div className="flex items-center">
              <Text className="px-2">{uploadedFile.name}</Text>
            </div>
            <IconButton variant="text" onClick={resetUploadedFile}>
              <Trash size={16} />
            </IconButton>
          </div>
        ) : (
          <>
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="h-[44px] w-full px-[12px] flex items-center justify-center bg-primary-purple hover:bg-primary-dark-purple text-white rounded-full transition"
            >
              {isExpanded ? (
                <Minus />
              ) : (
                <span className="flex items-center bg-primary-dark-purple text-lg font-semibold gap-2">
                  Brief <UploadIcon size={16} />
                </span>
              )}
            </button>

            {isExpanded && (
              <Uploader
                className="rounded-[20px] bg-primary-dark-purple-0 w-[192px]"
                onChange={handleFileUpload}
                accept={accept}
                multiple={false}
                beforeUpload={() => false}
                maxCount={1}
              >
                <div className="flex space-x-[10px] ml-[12px]">
                  <AutomatedPdfIcon />
                  <DocxIcon />
                  <DocIcon />
                </div>
              </Uploader>
            )}
          </>
        )}
      </div>
    </header>
  );
};
