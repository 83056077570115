import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { authApiSlice } from 'modules/auth/authApiSlice';
import { segmentsListApiSlice } from 'modules/segment/list/segmentsListApiSlice';

import { notifyError } from 'services/logService';

import { segmentsApi } from '../segmentApi';
import { getSegment } from '../segmentSelectors';
import { saveSegmentFailure, saveSegmentSuccess } from '../segmentSlice';
import { SegmentMode, SegmentResponse, SegmentState, SegmentStatuses } from '../types';
import { transformBucketsToTargetCombinations } from '../utils';

export function* segmentSaveSaga() {
  try {
    const segment: SegmentState = yield select(getSegment);
    const { ctr, attentionMetric, viewability, timeInView } = segment.performance;
    const { data: user } = yield select(authApiSlice.endpoints.userProfile.select(null));

    // add current bucket to buckets list in case we are using automated flow
    const buckets =
      (segment.mode === SegmentMode.automated && segment.buckets.length > 0) ||
      segment.mode === SegmentMode.manual
        ? segment.buckets
        : [...segment.buckets, segment.currentBucket];

    const targetCombinations = transformBucketsToTargetCombinations(buckets);

    const segmentData = {
      name: segment.name,
      status: SegmentStatuses.planning,
      userId: user.id,
      companyId: user.companyId,
      teamId: user.teamId,
      ssp: segment.ssp,
      countries: segment.geo,
      targetCombinations: targetCombinations,
      sensitiveSubjects: segment.sensitiveSubjects.sensitiveSubjectsValues,
      description: segment.description,
      async: segment.async,
      realtime: segment.realtime,
      ctr: ctr != undefined ? ctr / 100 : ctr,
      timeInView,
      attentionMetric,
      viewability:
        viewability !== undefined
          ? Number(Math.round(parseFloat(viewability / 100 + 'e6')) + 'e-6')
          : viewability,
      accuracyThreshold: segment.accuracyThreshold,
      parentId: segment.parentId,
      group: segment.group,
      hideNameForPartner: segment.hideNameForPartner,
      domainInclusionListId: segment.domainInclusionListId,
      domainExclusionListId: segment.domainExclusionListId,
      publicSegment: segment.ssp === 'Xandr' ? segment.publicSegment : false,
    };

    const response: SegmentResponse = yield call(segmentsApi.saveSegment, segmentData);

    yield put(saveSegmentSuccess(response));
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
  } catch (e) {
    notifyError(e);
    yield put(
      saveSegmentFailure({
        message: i18next.t('errors.segmentSaveFailed'),
      }),
    );
  }
}
