import { Progress } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { SentimentProgress } from '../../../../components/common/sentimentProgress';
import { DomainUrlData, OrgDomainNode } from '../../types';
import { PanelContentProps } from './PanelContent';

export const DomainPanel = ({ node }: PanelContentProps) => {
  if (!node) {
    return null;
  }

  return (
    <>
      <section className="mx-auto flex flex-col items-start gap-1 pb-8">
        <h2 className="text-primary-dark-purple-100 text-base-l font-bold">Domain</h2>
        <p className="max-w-2xl text-base font-light">{node.name}</p>
      </section>

      <section className="mx-auto flex flex-col items-start gap-1 pb-4 border-b border-opacity-25 border-nano-pca-purple">
        <h2 className="text-primary-dark-purple-100 text-base-l font-bold">Urls</h2>
      </section>

      <section className="mx-auto flex flex-col items-start gap-1 pt-4">
        <div className="flex flex-col gap-4 w-full">
          {(node as OrgDomainNode).domainUrlData.map((urlObj: DomainUrlData, index) => (
            <div
              key={index}
              className="flex flex-col gap-4 w-full border-b border-opacity-25 border-nano-pca-purple pb-4"
            >
              {/*Url*/}
              <div className="flex flex-row gap-2">
                <div className="text-base-l w-2/3">
                  <Link
                    to={urlObj.url}
                    target="_blank"
                    className="text-base-s no-underline cursor-pointer break-words"
                  >
                    {urlObj.url}
                  </Link>
                </div>
                <div className="w-1/3 px-1">
                  <Progress
                    percent={Math.round(urlObj.similarity * 100)}
                    className="w-full"
                    strokeColor="#7f5ea7"
                    trailColor="#f0f0f0"
                    strokeLinecap="round"
                    size="default"
                  />
                </div>
              </div>

              {/*Categories*/}
              <h2 className="text-primary-dark-purple-100 text-base-s font-bold">Categories</h2>
              <div>
                {urlObj.categories.map((categoryObj, index) => (
                  <div
                    key={index}
                    className="bborder flex flex-row gap-2 w-full bborder-b border-opacity-25 border-nano-pca-purple pb-4"
                  >
                    <div className="text-base-l w-2/3">
                      <p className="text-base-s"> {categoryObj.name} </p>
                    </div>
                    <div className="w-1/3 px-1 ">
                      <Progress
                        percent={Math.round(categoryObj.weight * 100)}
                        className="w-full grow"
                        strokeColor="#7f5ea7"
                        trailColor="#f0f0f0"
                        size={'small'}
                        steps={35}
                        status="active"
                      />
                    </div>
                  </div>
                ))}
              </div>

              {/*Sentiment*/}
              <div className="bborder flex flex-row gap-2 w-full">
                <div className="text-base-l w-2/3">
                  <p className="text-base-s font-bold"> Sentiment </p>
                </div>
                <div className="w-1/3 px-1">
                  <SentimentProgress sentimentValue={urlObj.sentiment} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
