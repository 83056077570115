import React from 'react';

import { OrgSegmentNode } from '../../types';

export const SegmentNodeContent = ({ node }: { node: OrgSegmentNode }) => {
  const active: boolean = node._highlighted || node._upToTheRootHighlighted;

  return (
    <div
      className={`flex flex-col justify-center items-center h-full w-full rounded-2xl ${
        active ? 'border-4 border-secondary-darkPurple' : ''
      }`}
    >
      <div className="bg-transparent h-full w-full rounded-xl p-2">
        <div className="rounded-2xl p-4 bg-radial-gradient w-full h-full flex flex-row justify-start items-center">
          <p className="uppercase text-base-xl whitespace-normal break-words w-full flex items-center justify-center text-secondary-white">
            {node.type}
          </p>
        </div>
      </div>
    </div>
  );
};
