import { ReactFlowProvider } from '@xyflow/react';

import { IntentEstimateFlowDiagram } from '../modules/intentEstimateFlowDiagram/IntentEstimateFlowDiagram';

export const IntentEstimateFlowDiagramPage = () => {
  return (
    <div className="w-full h-full">
      <ReactFlowProvider>
        <IntentEstimateFlowDiagram />
      </ReactFlowProvider>
    </div>
  );
};
