import { all, takeLatest } from 'redux-saga/effects';

import {
  deleteAdvertiser,
  getAdvertiserById,
  saveAdvertiser,
  updateAdvertiser,
} from './advertiserSlice';
import { deleteAdvertiserSaga } from './sagas/advertiserDeleteSaga';
import { saveAdvertiserSaga } from './sagas/advertiserSaveSaga';
import { updateAdvertiserSaga } from './sagas/advertiserUpdateSaga';
import { getAdvertiserByIdSaga } from './sagas/getAdvertiserSaga';

export function* advertiserSagas() {
  yield all([takeLatest(saveAdvertiser.type, saveAdvertiserSaga)]);
  yield all([takeLatest(deleteAdvertiser.type, deleteAdvertiserSaga)]);
  yield all([takeLatest(updateAdvertiser.type, updateAdvertiserSaga)]);
  yield all([takeLatest(getAdvertiserById.type, getAdvertiserByIdSaga)]);
}
