import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { Progress } from 'antd';
import { Search } from 'lucide-react';
import React, { ChangeEvent, useCallback, useMemo } from 'react';

import { Input } from 'components/common';

import { CategoryDistribution } from '../types';

type CategoryDistributionFlowNode = Node<CategoryDistribution>;

export const CategoryDistributionFlowNode = ({ data }: NodeProps<CategoryDistributionFlowNode>) => {
  const [searchQuery, setSearchQuery] = React.useState('');

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const filteredCategories = useMemo(() => {
    return searchQuery
      ? data.distribution?.filter((s) => s.name.toLowerCase().includes(searchQuery.toLowerCase()))
      : data.distribution;
  }, [data.distribution, searchQuery]);

  return (
    <div className="nowheel p-4 rounded-xl border border-nano-light-purple border-opacity-50 bg-secondary-white w-[500px] ">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center justify-between w-full">
          <p>Category distribution</p>

          <Input
            className="w-full max-w-[210px] h-[22px] border-b bbg-secondary-white bborder border-nano-pca-green border-opacity-25 text-base-s"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by category"
            prefix={
              <>
                <Search className="w-[10px] h-[10px] text" />{' '}
              </>
            }
          />
        </div>
        <div className="bborder overflow-y-auto h-[200px] scroll-">
          {filteredCategories.map(({ name, percentage }, index) => (
            <div className="flex gap-2 lg:flex-row flex-col justify-between" key={index}>
              <div className="flex flex-row items-center gap-2">
                <p className="text-base-s font-light whitespace-normal break-words">{name}</p>
              </div>

              <div>
                <Progress
                  className="p-2 w-full"
                  strokeColor="#7f5ea7"
                  type="line"
                  size="small"
                  status="active"
                  steps={20}
                  percent={Math.ceil(percentage)}
                />
              </div>
            </div>
          ))}

          <Handle type="source" position={Position.Right} className="ww-16 !bg-teal-500" />
          <Handle type="target" position={Position.Left} className="ww-16 !bg-teal-500" />
        </div>
      </div>
    </div>
  );
};
