import { PixelForm } from '../../modules/pixel/form/PixelForm';
import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

type PixelFormDialogProps = DialogBaseProps & {
  title: string;
  id?: string;
  name?: string;
  agree?: () => void;
};

export const PixelFormDialog = ({
  open,
  title,
  id,
  name,
  onClose,
  agree,
}: PixelFormDialogProps) => {
  return (
    <NanoDialog open={open} title={title} width={800}>
      <PixelForm onClose={onClose} refetch={agree} id={id} name={name} />
    </NanoDialog>
  );
};
