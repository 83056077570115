import { Progress } from 'antd';
import React from 'react';

import { OrgCategoryRootNode } from '../../types';
import { PanelContentProps } from './PanelContent';

export const CategoryRootPanel = ({ node }: PanelContentProps) => {
  if (!node) {
    return null;
  }

  return (
    <>
      <section className="mx-auto flex flex-col items-start gap-1 pb-8">
        <h2 className="text-primary-dark-purple-100 text-base-l font-bold">
          Category distribution
        </h2>
        <p className="max-w-2xl text-base font-light">
          How categories are distributed in this intent group
        </p>
      </section>

      <section className="mx-auto flex flex-col items-start gap-1">
        <h2 className="text-primary-dark-purple-100 text-base-l font-bold pb-8">Categories</h2>

        <div className="flex flex-col gap-4 w-full">
          {(node as OrgCategoryRootNode).categoryDistribution.distribution.map(
            (categoryObj, index) => (
              <div
                key={index}
                className="flex flex-row gap-2 w-full border-b border-opacity-25 border-nano-pca-purple pb-4"
              >
                <div className="text-base-l w-2/3">
                  <p className=""> {categoryObj.name} </p>
                </div>
                <div className="w-1/3 px-1">
                  <Progress
                    percent={Math.round(categoryObj.percentage)}
                    className="w-full grow"
                    strokeColor="#7f5ea7"
                    trailColor="#f0f0f0"
                    size={'small'}
                    steps={35}
                    status="active"
                  />
                </div>
              </div>
            ),
          )}
        </div>
      </section>
    </>
  );
};
