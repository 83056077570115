import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { advertiserApi } from '../advertiserApi';
import { advertiserListApiSlice } from '../advertiserListApiSlice';
import { getAdvertiser } from '../advertiserSelectors';
import { getAdvertiserByIdFailure, getAdvertiserByIdSuccess } from '../advertiserSlice';
import { AdvertiserResponse, AdvertiserState } from '../constants';

export function* getAdvertiserByIdSaga() {
  try {
    const advertiserState: AdvertiserState = yield select(getAdvertiser);

    const advertiserResponse: AdvertiserResponse = yield call(
      advertiserApi.getAdvertiserById,
      advertiserState.id,
    );

    yield put(advertiserListApiSlice.util.invalidateTags(['Advertiser']));
    yield put(getAdvertiserByIdSuccess(advertiserResponse));
  } catch (e) {
    notifyError(e);

    yield put(
      getAdvertiserByIdFailure({
        message: i18next.t('pages.advertiser.errors.advertiserFetchFailed'),
      }),
    );
  }
}
