import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PixelFormData, PixelItem, PixelState } from './constants';

export const PIXEL_SLICE_NAME = 'pixel';

export const initialState: PixelState = {
  name: '',
  id: '',
  companyId: null,
  teamId: null,
  userId: null,
  isLoading: false,
  isCreated: false,
  isUpdated: false,
  isUpdating: false,
  pixelToDeleteId: '',
  errorMessage: null,
  advertiserId: null,
  piggyBacks: [],
  type: 'profile',
};

export const pixelSlice = createSlice({
  name: PIXEL_SLICE_NAME,
  initialState,
  reducers: {
    savePixel: (state: PixelState) => {
      state.isLoading = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    savePixelFailure: (state: PixelState, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
    },
    savePixelSuccess: (state: PixelState, { payload }) => {
      state.isLoading = false;
      state.isCreated = true;
      state.id = payload.id;
    },
    clearPixel: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deletePixel: (state: PixelState, { payload }: PayloadAction<{ id: string }>) => {
      state.isLoading = true;
    },
    deletePixelSuccess: (state: PixelState) => {
      state.isLoading = false;
      state.pixelToDeleteId = '';
      state.isCreated = false;
    },
    deletePixelFailure: (
      state: PixelState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.isCreated = false;
    },
    setPixelToDeleteId: (state: PixelState, { payload }: PayloadAction<string>) => {
      state.pixelToDeleteId = payload;
    },
    updatePixel: (state: PixelState) => {
      state.isLoading = true;
    },
    updatePixelSuccess: (state: PixelState) => {
      state.isLoading = false;
      state.isCreated = true;
    },
    updatePixelFailure: (
      state: PixelState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.isCreated = false;
    },
    updateValues: (state: PixelState, { payload }: PayloadAction<PixelFormData>) => {
      return {
        ...state,
        ...payload,
      };
    },
    setPixelToUpdateId: (state: PixelState, { payload }: PayloadAction<string>) => {
      state.id = payload;
    },
    getPixelById: (state: PixelState, { payload }: PayloadAction<string>) => {
      state.isLoading = true;
      state.id = payload;
    },
    getPixelByIdSuccess: (state: PixelState, { payload }: PayloadAction<PixelItem>) => {
      state.isLoading = false;
      state.name = payload.name;
      state.advertiserId = payload.advertiserId;
      state.companyId = payload.companyId;
      state.teamId = payload.teamId;
      state.userId = payload.userId;
      state.piggyBacks = payload.piggyBacks;
      state.type = payload.type;
    },
    getPixelByIdFailure: (state: PixelState, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    },
  },
});

export const {
  savePixel,
  savePixelFailure,
  savePixelSuccess,
  clearPixel,
  deletePixel,
  deletePixelFailure,
  deletePixelSuccess,
  setPixelToDeleteId,
  setPixelToUpdateId,
  updatePixel,
  updatePixelFailure,
  updatePixelSuccess,
  updateValues,
  getPixelById,
  getPixelByIdFailure,
  getPixelByIdSuccess,
} = pixelSlice.actions;
export const pixel = pixelSlice.reducer;
