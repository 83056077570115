import { nanoApi } from '../../redux/rootApiSlice';
import { ADVERTISER_URL, AdvertiserItem } from './constants';

export const advertiserListApiSlice = nanoApi.injectEndpoints({
  endpoints: (build) => ({
    advertiserList: build.query<AdvertiserItem[], null>({
      query: () => ({ method: 'GET', url: ADVERTISER_URL }),
      providesTags: ['Advertiser'],
    }),
  }),
});

export const { useAdvertiserListQuery } = advertiserListApiSlice;
