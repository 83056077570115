import { Skeleton } from 'antd';
import { ChevronDown } from 'feather-icons-react';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Field, Input, Option, Select, SubmitButton } from 'components/common';

import { useCompanyListQuery } from 'modules/adminPanel/company';
import { useTeamListQuery } from 'modules/adminPanel/team/list/teamListApiSlice';
import { useUsersListQuery } from 'modules/adminPanel/users';
import { useUserProfileQuery } from 'modules/auth/authApiSlice';

import { useGeoQuery } from '../../apiData/apiDataApiSlice';
import { notificationService } from '../../notifications';
import { getAdvertiser } from '../advertiserSelectors';
import {
  saveAdvertiser,
  setAdvertiserToUpdateId,
  updateAdvertiser,
  updateValues,
} from '../advertiserSlice';
import { AdvertiserFormData } from '../constants';

export type AdvertiserFormProps = {
  id?: string;
  name?: string;
  onClose: () => void;
  refetch?: () => void;
};

export const AdvertiserForm = ({ id, name, onClose }: AdvertiserFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentAdvertiser = useSelector(getAdvertiser);
  const { data: user, isLoading } = useUserProfileQuery(null);
  const { data: geoValues } = useGeoQuery(null);
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: errorCompanies,
  } = useCompanyListQuery(null);
  const { data: teams, isLoading: isLoadingTeams, error: errorTeams } = useTeamListQuery(null);
  const { data: users, isLoading: isLoadingUsers, error: errorUsers } = useUsersListQuery(null);
  const isLoaded = !isLoading && !isLoadingCompanies && !isLoadingTeams && !isLoadingUsers;

  const showSuccessNotification = useCallback(() => {
    notificationService.showSuccess({
      key: 'advertiserFormSuccess',
      message: t('pages.advertiser.success.advertiserSaveSuccess'),
    });
  }, [t]);

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AdvertiserFormData>({
    defaultValues: id
      ? currentAdvertiser // Use current advertiser values for editing
      : {
          companyId: user ? user.companyId : null,
          teamId: user ? user.teamId : null,
          userId: user ? user.id : null,
          country: 'GB',
          name: name,
        },
  });

  const companyId = watch('companyId');
  const teamId = watch('teamId');

  const onSubmit = handleSubmit((data) => {
    dispatch(updateValues(data));
    if (!id) {
      dispatch(saveAdvertiser());
    } else {
      dispatch(setAdvertiserToUpdateId(id));
      dispatch(updateAdvertiser());
    }
  });

  const onCompanyChange = () => {
    setValue('teamId', null);
    setValue('userId', null);
  };

  const onTeamChange = () => {
    setValue('userId', null);
  };

  useEffect(() => {
    if (errorCompanies || errorTeams || errorUsers) {
      notificationService.showError({
        key: 'AdvertiserFailed',
        message: t('pages.advertiser.errors.advertiserFetchFailed'),
      });
    }
  }, [errorCompanies, errorTeams, errorUsers, t]);

  useEffect(() => {
    if (!currentAdvertiser.isLoading && currentAdvertiser.isCreated) {
      onClose();
      showSuccessNotification();
    }
  }, [
    id,
    currentAdvertiser,
    name,
    currentAdvertiser.isLoading,
    onClose,
    showSuccessNotification,
    currentAdvertiser.isCreated,
  ]);

  useEffect(() => {
    if (currentAdvertiser && id) {
      reset(currentAdvertiser);
    }
  }, [currentAdvertiser, id, reset]);

  return (
    <form className="flex flex-col gap-y-6" onSubmit={onSubmit}>
      {!isLoaded ? (
        <Skeleton className="h-[660px]" active />
      ) : (
        <>
          <Field
            name="name"
            label={t('pages.advertiser.form.name')}
            control={control}
            render={({ field, fieldState: { invalid } }) => (
              <Input placeholder={t('pages.advertiser.form.name')} {...field} invalid={invalid} />
            )}
            rules={{
              required: t('forms.errors.required'),
            }}
            error={errors.name?.message}
          />
          <Field
            name="url"
            control={control}
            label={t('pages.advertiser.form.url')}
            render={({ field, fieldState: { invalid } }) => (
              <Input placeholder={t('pages.advertiser.form.url')} {...field} invalid={invalid} />
            )}
            rules={{
              required: t('adminPanel.customCategories.errors.forms.required'),
              pattern: {
                value: /https?:\/\/[^\s"]+/g,
                message: t('adminPanel.contexta.errors.invalidUrl'),
              },
            }}
            error={errors.url?.message}
          />
          <Field
            name="country"
            control={control}
            label={t('pages.advertiser.form.country')}
            render={({ field, fieldState: { invalid } }) => (
              <Select
                placeholder={t('pages.advertiser.form.country')}
                suffixIcon={<ChevronDown />}
                invalid={invalid}
                {...field}
              >
                {geoValues?.map((geo) => (
                  <Option key={geo.id} value={geo.iso}>
                    {geo.location}
                  </Option>
                ))}
              </Select>
            )}
            rules={{
              required: t('forms.errors.required'),
            }}
            error={errors.country?.message}
          />
          <Field
            name="companyId"
            control={control}
            label={t('pages.advertiser.form.company')}
            render={({ field, fieldState: { invalid } }) => (
              <Select
                placeholder={t('pages.advertiser.form.company')}
                suffixIcon={<ChevronDown />}
                invalid={invalid}
                {...field}
                onChange={(value) => {
                  field.onChange(value);
                  onCompanyChange();
                }}
              >
                {companies?.map((company) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </Select>
            )}
            rules={{
              required: t('forms.errors.required'),
            }}
            error={errors.companyId?.message}
          />
          <Field
            name="teamId"
            control={control}
            label={t('pages.advertiser.form.team')}
            render={({ field, fieldState: { invalid } }) => (
              <Select
                placeholder={t('pages.advertiser.form.team')}
                suffixIcon={<ChevronDown />}
                disabled={!watch('companyId')}
                invalid={invalid}
                {...field}
                onChange={(value) => {
                  field.onChange(value);
                  onTeamChange();
                }}
              >
                {teams
                  ?.filter((team) => team.companyId === companyId)
                  .map((team) => (
                    <Option key={team.id} value={team.id}>
                      {team.name}
                    </Option>
                  ))}
              </Select>
            )}
            rules={{
              required: t('forms.errors.required'),
            }}
            error={errors.teamId?.message}
          />
          <Field
            name="userId"
            control={control}
            label={t('pages.advertiser.form.user')}
            render={({ field, fieldState: { invalid } }) => (
              <Select
                placeholder={t('pages.advertiser.form.user')}
                suffixIcon={<ChevronDown />}
                disabled={!watch('teamId')}
                invalid={invalid}
                {...field}
              >
                {users
                  ?.filter((user) => user.teamId === teamId)
                  .map((user) => (
                    <Option key={user.id} value={user.id}>
                      {user.name} {user.surname}
                    </Option>
                  ))}
              </Select>
            )}
            rules={{
              required: t('forms.errors.required'),
            }}
            error={errors.userId?.message}
          />
          <div className="mt-2 flex items-center justify-end gap-x-4">
            <Button type="button" variant="outline" onClick={onClose}>
              {t('adminPanel.domainList.dialog.cancelButton')}
            </Button>
            <SubmitButton>{t('adminPanel.domainList.dialog.submitButton')}</SubmitButton>
          </div>
        </>
      )}
    </form>
  );
};
