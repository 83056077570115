import { Progress } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { DomainUrlData, OrgDomainRootNode } from '../../types';
import { PanelContentProps } from './PanelContent';

export const DomainRootPanel = ({ node }: PanelContentProps) => {
  if (!node) {
    return null;
  }

  return (
    <>
      <section className="mx-auto flex flex-col items-start gap-1 pb-8">
        <h2 className="text-primary-dark-purple-100 text-base-l font-bold">Domains</h2>
        <p className="max-w-2xl text-base font-light">
          All domains targeted using intent prompts from this intent group
        </p>
      </section>

      <section className="mx-auto flex flex-col items-start gap-1">
        {/*<h2 className="text-primary-dark-purple-100 text-base-l font-bold pb-8">Domains</h2>*/}

        <div className="flex flex-col gap-4 w-full">
          {Object.entries((node as OrgDomainRootNode).domainMap).map(
            ([domainName, domainUrlData], index) => (
              <div
                key={index}
                className="flex flex-col gap-2 p-2 border-t border-opacity-25 border-nano-pca-purple"
              >
                <p className="text-primary-dark-purple-100 text-base-l font-bold">{domainName}</p>

                {domainUrlData.map((urlData: DomainUrlData, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-2 w-full bborder-b border-opacity-25 border-nano-pca-purple"
                  >
                    <div className="text-base-l w-2/3">
                      <Link
                        to={urlData.url}
                        target="_blank"
                        className="text-base-s no-underline cursor-pointer break-words"
                      >
                        {urlData.url}
                      </Link>
                    </div>
                    <div className="w-1/3 px-1">
                      <Progress
                        percent={Math.round(urlData.similarity * 100)}
                        className="w-full"
                        strokeColor="#7f5ea7"
                        trailColor="#f0f0f0"
                        strokeLinecap="round"
                        size="default"
                      />
                    </div>
                  </div>
                ))}
              </div>
            ),
          )}
        </div>
      </section>
    </>
  );
};
