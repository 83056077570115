import { all, takeLatest } from 'redux-saga/effects';

import { deletePixel, getPixelById, savePixel, updatePixel } from './pixelSlice';
import { getPixelByIdSaga } from './sagas/getPixelSaga';
import { deletePixelSaga } from './sagas/pixelDeleteSaga';
import { savePixelSaga } from './sagas/pixelSaveSaga';
import { updatePixelSaga } from './sagas/pixelUpdateSaga';

export function* pixelSagas() {
  yield all([takeLatest(savePixel.type, savePixelSaga)]);
  yield all([takeLatest(deletePixel.type, deletePixelSaga)]);
  yield all([takeLatest(updatePixel.type, updatePixelSaga)]);
  yield all([takeLatest(getPixelById.type, getPixelByIdSaga)]);
}
