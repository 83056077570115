import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { PixelFormData, PixelState } from '../constants';
import { pixelApi } from '../pixelApi';
import { pixelListApiSlice } from '../pixelListApiSlice';
import { getPixel } from '../pixelSelectors';
import { updatePixelFailure, updatePixelSuccess } from '../pixelSlice';

export function* updatePixelSaga() {
  try {
    const pixel: PixelState = yield select(getPixel);

    const pixelData: PixelFormData = {
      name: pixel.name,
      advertiserId: pixel.advertiserId,
      companyId: pixel.companyId,
      teamId: pixel.teamId,
      userId: pixel.userId,
      piggyBacks: pixel.piggyBacks.filter((value) => value.trim() !== ''),
      type: pixel.type,
    };

    yield call(pixelApi.updatePixel, pixel.id, pixelData);

    yield put(pixelListApiSlice.util.invalidateTags(['Pixel']));
    yield put(updatePixelSuccess());
  } catch (e) {
    notifyError(e);

    yield put(
      updatePixelFailure({
        message: i18next.t('pages.pixel.errors.pixelUpdateFailed'),
      }),
    );
  }
}
