import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { authApiSlice } from 'modules/auth/authApiSlice';
import { segmentsListApiSlice } from 'modules/segment/list/segmentsListApiSlice';

import { notifyError } from 'services/logService';

import { segmentsApi } from '../segmentApi';
import { PerformanceMetrics, SegmentDetailsResponse } from '../segmentApiTypes';
import { getPerformanceMetricsSelector, getSegment } from '../segmentSelectors';
import {
  setSegmentInfo,
  updateSegmentFailure,
  updateSegmentSuccess,
  updateValues,
} from '../segmentSlice';
import { SegmentState } from '../types';
import { transformBucketsToTargetCombinations } from '../utils';

export function* segmentUpdateSaga({ payload }: PayloadAction<{ id: string }>) {
  try {
    const segment: SegmentState = yield select(getSegment);
    const performance: PerformanceMetrics = yield select(getPerformanceMetricsSelector);
    const { data: user } = yield select(authApiSlice.endpoints.userProfile.select(null));

    const targetCombinations = transformBucketsToTargetCombinations(segment.buckets);

    const segmentData = {
      name: segment.name,
      status: segment.status,
      userId: user.id,
      companyId: user.companyId,
      teamId: user.teamId,
      ssp: segment.ssp,
      countries: segment.geo,
      targetCombinations,
      sensitiveSubjects: segment.sensitiveSubjects.sensitiveSubjectsValues,
      description: segment.description,
      dsp: segment.dsp,
      dspSeatId: segment.dspSeatId,
      sspDealId: segment.sspDealId,
      sspSeatId: segment.sspSeatId,
      sspSegmentId: segment.sspSegmentId,
      expiredDate: segment.expiredDate,
      debugUntil: segment.debugUntil,
      async: segment.async,
      realtime: segment.realtime,
      labels: segment.labels,
      parentId: segment.parentId,
      group: segment.group,
      hideNameForPartner: segment.hideNameForPartner,
      ctr:
        performance.ctr !== undefined
          ? Number(Math.round(parseFloat(performance.ctr / 100 + 'e6')) + 'e-6')
          : performance.ctr,
      attentionMetric: performance.attentionMetric,
      timeInView: performance.timeInView,
      viewability:
        performance.viewability !== undefined
          ? Number(Math.round(parseFloat(performance.viewability / 100 + 'e6')) + 'e-6')
          : performance.viewability,
      accuracyThreshold: segment.accuracyThreshold,
      domainInclusionListId: segment.domainInclusionListId,
      domainExclusionListId: segment.domainExclusionListId,
      publicSegment: segment.ssp === 'Xandr' ? segment.publicSegment : false,
    };

    const segmentDetails: SegmentDetailsResponse = yield call(
      segmentsApi.updateSegment,
      payload.id,
      segmentData,
    );
    let debugStatus = '';

    if (segmentDetails.debugUntil !== null) {
      const debugDate = new Date(segmentDetails.debugUntil);
      const today = new Date();

      debugDate.setHours(23, 59, 59, 999); // Set the date to the end of the day
      debugStatus = debugDate >= today ? 'Active' : 'Inactive';
    }

    yield put(
      updateValues({
        name: segmentDetails.name,
        ssp: segmentDetails.ssp,
        async: segmentDetails.async,
        realtime: segmentDetails.realtime,
        debugUntil: segmentDetails.debugUntil,
        debugUntilStatus: debugStatus,
        parentId: segment.parentId,
        group: segment.group,
        hideNameForPartner: segment.hideNameForPartner,
        domainInclusionListId: segment.domainInclusionListId,
        domainExclusionListId: segment.domainExclusionListId,
      }),
    );
    yield put(setSegmentInfo(segmentDetails));
    yield put(segmentsListApiSlice.util.invalidateTags(['Segments']));
    yield put(updateSegmentSuccess());
  } catch (e) {
    notifyError(e);
    yield put(
      updateSegmentFailure({
        message: i18next.t('errors.segmentSaveFailed'),
      }),
    );
  }
}
