import { Handle, Position } from '@xyflow/react';

export const SentimentDistributionFlowNode = () => {
  return (
    <div className="text-base-l border p-2 rounded border-opacity-25">
      {' '}
      Sentiment Distribution
      <div className="text-base-s"> Positive 50%</div>
      <div className="text-base-s"> Negative 10%</div>
      <div className="text-base-s"> All 758</div>
      <Handle type="source" position={Position.Right} className="ww-16 !bg-teal-500" />
      <Handle type="target" position={Position.Left} className="ww-16 !bg-teal-500" />
    </div>
  );
};
