import { Edge, Node } from '@xyflow/react';

import { IntentEstimateDiagramResponse } from '../types';
import { CategoryDistributionFlowNode } from './CategoryDistributionFlowNode';
import { IntentGroupFlowNode } from './IntentGroupFlowNode';
import { SegmentFlowNode } from './SegmentFlowNode';
import { SentencesFlowNode } from './SentencesFlowNode';
import { SentimentDistributionFlowNode } from './SentimentDistributionFlowNode';
import { UrlsFlowNode } from './UrlsFlowNode';

export type nodeTypeKeys =
  | 'segmentNode'
  | 'categoryDistributionNode'
  | 'sentencesNode'
  | 'sentimentDistributionNode'
  | 'vectorGroupNode';

export const nodeTypes = {
  segmentFlowNode: SegmentFlowNode,
  intentGroupFlowNode: IntentGroupFlowNode,
  sentencesFlowNode: SentencesFlowNode,
  categoryDistributionFlowNode: CategoryDistributionFlowNode,
  sentimentDistributionFlowNode: SentimentDistributionFlowNode,
  domainMapFlowNode: UrlsFlowNode,
};

export const createNodesAndEdges = (data: IntentEstimateDiagramResponse) => {
  let edges: Edge[] = [];

  const samePosition = { x: 0, y: 0 };
  const nodes: Node[] = [
    {
      id: '1',
      position: samePosition,
      data: { name: data.segmentName, id: data.segmentId },
      type: 'segmentFlowNode',
    },
  ];

  for (const [clusterIndex, intentCluster] of data.intentClusters.entries()) {
    const sentencesNodeId = 'sentencesNodeId' + clusterIndex;
    const sentencesGroupDataNode: Node = {
      id: sentencesNodeId,
      position: samePosition,
      data: { sentences: intentCluster.sentences },
      type: 'sentencesFlowNode',
    };

    // Id will follow: edge + sentence + group naming

    const sentencesEdgeId = 'sentenceEdgeId' + clusterIndex;
    const sentencesEdge: Edge = {
      id: sentencesEdgeId,
      source: '1',
      target: sentencesNodeId,
      animated: true,
    };

    edges.push(sentencesEdge);

    for (const [intentGroupIndex, intentGroup] of intentCluster.intentGroups.entries()) {
      const intentGroupNodeId = 'intentGroupNodeId' + intentGroupIndex;

      const intentGroupDataNode: Node = {
        id: intentGroupNodeId,
        position: samePosition,
        data: { intentGroupName: intentGroup.name, keywords: intentGroup.keywords },
        type: 'intentGroupFlowNode',
      };

      const categoryDistributionDataNode: Node = {
        id: intentGroupNodeId + 'n1-child',
        position: samePosition,
        data: intentGroup.categoryDistribution,
        type: 'categoryDistributionFlowNode',
      };

      const sentimentDistributionDataNode: Node = {
        id: intentGroupNodeId + 'n2-child',
        position: samePosition,
        data: intentGroup.sentimentDistribution,
        type: 'sentimentDistributionFlowNode',
      };

      const domainMapNode: Node = {
        id: intentGroupNodeId + 'n3-child',
        position: samePosition,
        data: intentGroup.domainMap,
        type: 'domainMapFlowNode',
      };

      nodes.push(
        intentGroupDataNode,
        categoryDistributionDataNode,
        sentimentDistributionDataNode,
        domainMapNode,
      );

      const intentGroupEdgeId = 'intentGroupEdgeId' + intentGroupIndex;

      edges = edges.concat([
        {
          // Intent Group edge
          id: sentencesEdgeId + intentGroupEdgeId,
          source: sentencesNodeId,
          target: intentGroupNodeId,
          animated: true,
        },
        {
          id: sentencesEdgeId + intentGroupEdgeId + 'n1-child',
          source: intentGroupNodeId,
          target: intentGroupNodeId + 'n1-child',
          animated: true,
        },
        {
          id: sentencesEdgeId + intentGroupEdgeId + 'n2-child',
          source: intentGroupNodeId + 'n1-child',
          target: intentGroupNodeId + 'n2-child',
          animated: true,
        },
        {
          id: sentencesEdgeId + intentGroupEdgeId + 'n3-child',
          source: intentGroupNodeId + 'n2-child',
          target: intentGroupNodeId + 'n3-child',
          animated: true,
        },
      ]);
    }

    nodes.push(sentencesGroupDataNode);
  }

  return {
    nodes,
    edges,
  };
};

export const mockData: IntentEstimateDiagramResponse = {
  segmentId: '6756d9f64acfa88ff3b2d27d',
  segmentName: 'Test ST 09/12/2024 12:52',
  intentClusters: [
    {
      sentences: [
        '"Mobile payments have simplified transactions  making it easy to pay bills  shop online  or transfer money with just a few taps."',
        '"Mobile e-commerce has made shopping more convenient  with products from around the world available at our fingertips."',
        '"Mobile learning platforms provide educational content that can be accessed anytime  anywhere  making learning more flexible and accessible."',
        '"The evolution of smartphones has revolutionized the way we communicate  with new models offering advanced features and technologies every year."',
        '"Mobile apps have become an integral part of our daily lives  from banking to shopping to entertainment  there\'s an app for everything."',
        '"Mobile gaming has seen a surge in popularity with games like Candy Crush and PUBG attracting millions of players worldwide."',
        '"Mobile marketing strategies are essential for businesses in the digital age  reaching customers directly on their smartphones."',
        '"Mobile health apps are transforming healthcare  allowing users to monitor their health and access medical services remotely."',
        '"Mobile operating systems like Android and iOS are the backbone of smartphones  powering the apps and features we use daily."',
        '"With the increasing use of smartphones  mobile security has become a major concern  protecting personal data from cyber threats is crucial."',
        '"Mobile device repair services are essential for fixing common issues like cracked screens  battery problems  and software glitches."',
        '"Mobile photography has been enhanced by the advanced camera features in smartphones  making professional-quality photos accessible to everyone."',
        '"Mobile device accessories like cases  headphones  and chargers enhance the usability and functionality of our smartphones."',
        '"Mobile virtual reality offers immersive experiences through VR headsets paired with smartphones."',
        '"Mobile networks connect us to the internet  enabling browsing  streaming  and social media use on the go."',
        '"Mobile data plans offer internet access on the go  with various options for speed  data limits  and pricing."',
        '"Mobile social media apps like Instagram and Snapchat have changed the way we communicate and share our lives."',
        '"Mobile streaming services like Netflix and Spotify provide entertainment on the go  from movies to music to podcasts."',
      ],
      intentGroups: [
        {
          name: 'Intent group 1',
          keywords: [
            '"Mobile payments have simplified transactions  making it easy to pay bills  shop online  or transfer money with just a few taps."',
            '"Mobile e-commerce has made shopping more convenient  with products from around the world available at our fingertips."',
            '"Mobile learning platforms provide educational content that can be accessed anytime  anywhere  making learning more flexible and accessible."',
          ],
          categoryDistribution: {
            totalCount: 61,
            totalUniqueCount: 34,
            distribution: [
              {
                name: 'Internet & Telecom/Mobile & Wireless',
                percentage: 8.19672131147541,
                count: 5,
              },
              {
                name: 'Internet & Telecom',
                percentage: 8.19672131147541,
                count: 5,
              },
              {
                name: 'Shopping',
                percentage: 8.19672131147541,
                count: 5,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                percentage: 8.19672131147541,
                count: 5,
              },
              {
                name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                percentage: 4.918032786885246,
                count: 3,
              },
              {
                name: 'Business & Industrial',
                percentage: 4.918032786885246,
                count: 3,
              },
              {
                name: 'News',
                percentage: 3.278688524590164,
                count: 2,
              },
              {
                name: 'Jobs & Education/Education/Open Online Courses',
                percentage: 3.278688524590164,
                count: 2,
              },
              {
                name: 'Shopping/Consumer Resources',
                percentage: 3.278688524590164,
                count: 2,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                percentage: 3.278688524590164,
                count: 2,
              },
              {
                name: 'Shopping/Shopping Portals',
                percentage: 3.278688524590164,
                count: 2,
              },
              {
                name: 'Jobs & Education',
                percentage: 3.278688524590164,
                count: 2,
              },
              {
                name: 'Jobs & Education/Education/Distance Learning',
                percentage: 3.278688524590164,
                count: 2,
              },
              {
                name: 'Computers & Electronics/Software/Educational Software',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Events & Listings/Live Sporting Events',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'People & Society/Family & Relationships/Family',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Shopping/Consumer Resources/Consumer Advocacy & Protection',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Business & Industrial/Business Services',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Finance/Banking',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Software',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Finance/Banking/Mobile Payments & Digital Wallets',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Shopping/Auctions',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Internet & Telecom/Service Providers/Phone Service Providers',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Finance',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Business & Industrial/Business Services/E-Commerce Services',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'News/Business News/Company News',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Computers & Electronics',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Food & Drink/Food & Grocery Retailers',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'People & Society',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'News/Technology News',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Food & Drink',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'People & Society/Family & Relationships',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Arts & Entertainment',
                percentage: 1.639344262295082,
                count: 1,
              },
              {
                name: 'Shopping/Classifieds',
                percentage: 1.639344262295082,
                count: 1,
              },
            ],
          },
          sentimentDistribution: {
            totalCount: 10,
            positiveCount: 7,
            negativeCount: 3,
            percentPositive: 70,
            percentNegative: 30,
          },
          domainMap: {
            'businessinsider.com': [
              {
                url: 'https://www.businessinsider.com/online-learning-platform-comparison-udemy-skillshare-lynda-coursera/',
                language: 'en',
                categories: [
                  {
                    name: 'Jobs & Education',
                    weight: 2.6294975,
                  },
                  {
                    name: 'Jobs & Education/Education/Distance Learning',
                    weight: 0.9985688,
                  },
                  {
                    name: 'Jobs & Education/Education/Open Online Courses',
                    weight: 0.9647445,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.9014036,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.9014036,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.9014036,
                  },
                ],
                sentiment: 0.6838705539703369,
                similarity: 0.43602168560028076,
              },
            ],
            'digitaltrends.com': [
              {
                url: 'https://www.digitaltrends.com/mobile/best-apps-for-teachers-education/',
                language: 'en',
                categories: [
                  {
                    name: 'Jobs & Education',
                    weight: 2.6875455,
                  },
                  {
                    name: 'Computers & Electronics',
                    weight: 1.106271,
                  },
                  {
                    name: 'Jobs & Education/Education/Distance Learning',
                    weight: 0.9975631,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.925476,
                  },
                  {
                    name: 'Computers & Electronics/Software/Educational Software',
                    weight: 0.925476,
                  },
                  {
                    name: 'Jobs & Education/Education/Open Online Courses',
                    weight: 0.8973073,
                  },
                ],
                sentiment: 0.7538755536079407,
                similarity: 0.5485507249832153,
              },
              {
                url: 'https://www.digitaltrends.com/mobile/samsung-pay-vs-google-pay/',
                language: 'en',
                categories: [
                  {
                    name: 'Finance',
                    weight: 1.0390636,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 0.9994679,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.9994679,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9994679,
                  },
                  {
                    name: 'Finance/Banking',
                    weight: 0.99901545,
                  },
                  {
                    name: 'Finance/Banking/Mobile Payments & Digital Wallets',
                    weight: 0.99901545,
                  },
                  {
                    name: 'Business & Industrial/Business Services/E-Commerce Services',
                    weight: 0.8887916,
                  },
                  {
                    name: 'Business & Industrial',
                    weight: 0.8887916,
                  },
                  {
                    name: 'Business & Industrial/Business Services',
                    weight: 0.8887916,
                  },
                ],
                sentiment: 0.66057950258255,
                similarity: 0.4555298984050751,
              },
            ],
            'manchestereveningnews.co.uk': [
              {
                url: 'https://www.manchestereveningnews.co.uk/whats-on/shopping/im-vinted-pro--simple-29691596/',
                language: 'en',
                categories: [
                  {
                    name: 'Shopping',
                    weight: 4.7142897,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Consumer Advocacy & Protection',
                    weight: 0.99047697,
                  },
                  {
                    name: 'Shopping/Shopping Portals',
                    weight: 0.9023476,
                  },
                ],
                sentiment: -0.49618226289749146,
                similarity: 0.44178900122642517,
              },
            ],
            'metro.co.uk': [
              {
                url: 'https://metro.co.uk/2023/04/18/is-it-cheaper-to-buy-groceries-online-or-in-the-supermarket-18587373/amp/',
                language: 'en',
                categories: [
                  {
                    name: 'Shopping',
                    weight: 4.0566883,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.9652052,
                  },
                  {
                    name: 'Food & Drink/Food & Grocery Retailers',
                    weight: 0.9443388,
                  },
                  {
                    name: 'Food & Drink',
                    weight: 0.9443388,
                  },
                ],
                sentiment: -0.13331539928913116,
                similarity: 0.436234712600708,
              },
            ],
            'mumsnet.com': [
              {
                url: 'https://www.mumsnet.com/talk/_chat/5096678-parental-controls-on-kids-smartphones/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 2.0168285,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.99716043,
                  },
                  {
                    name: 'People & Society',
                    weight: 0.99399096,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9921744,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.9921744,
                  },
                  {
                    name: 'People & Society/Family & Relationships',
                    weight: 0.9537063,
                  },
                  {
                    name: 'People & Society/Family & Relationships/Family',
                    weight: 0.9537063,
                  },
                ],
                sentiment: 0.7792444825172424,
                similarity: 0.4318903684616089,
              },
            ],
            'nottinghampost.com': [
              {
                url: 'https://www.nottinghampost.com/whats-on/music-nightlife/order-food-drink-motorpoint-arena-2832256/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 1.4369925,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.99756587,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 0.99756587,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.99756587,
                  },
                  {
                    name: 'Arts & Entertainment/Events & Listings/Live Sporting Events',
                    weight: 0.9956388,
                  },
                ],
                sentiment: 0.40867549180984497,
                similarity: 0.4496927261352539,
              },
            ],
            'nypost.com': [
              {
                url: 'https://nypost.com/2024/11/08/shopping/roamless-esim-app/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 4.580843,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.9967006,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.99010336,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/Phone Service Providers',
                    weight: 0.97519773,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9367236,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.8275667,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.8275667,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.8275667,
                  },
                ],
                sentiment: 0.30751311779022217,
                similarity: 0.5144540071487427,
              },
            ],
            'techradar.com': [
              {
                url: 'https://www.techradar.com/pro/want-to-launch-a-super-app-heres-what-you-need-to-know/',
                language: 'en',
                categories: [
                  {
                    name: 'News',
                    weight: 1.1921815,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.0561112,
                  },
                  {
                    name: 'Business & Industrial',
                    weight: 1.0171893,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9999957,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.9999957,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.9659435,
                  },
                ],
                sentiment: 0.4100930690765381,
                similarity: 0.4591279625892639,
              },
            ],
            'telegraph.co.uk': [
              {
                url: 'https://www.telegraph.co.uk/money/consumer-affairs/make-1000s-renting-cameras-cars-online/',
                language: 'en',
                categories: [
                  {
                    name: 'Shopping',
                    weight: 3.0326746,
                  },
                  {
                    name: 'Business & Industrial',
                    weight: 2.1659384,
                  },
                  {
                    name: 'News',
                    weight: 1.314218,
                  },
                  {
                    name: 'Shopping/Classifieds',
                    weight: 0.9926036,
                  },
                  {
                    name: 'Shopping/Shopping Portals',
                    weight: 0.9602217,
                  },
                  {
                    name: 'Shopping/Auctions',
                    weight: 0.9170635,
                  },
                  {
                    name: 'News/Business News/Company News',
                    weight: 0.80215347,
                  },
                ],
                sentiment: -0.30075907707214355,
                similarity: 0.44650232791900635,
              },
            ],
          },
        },
        {
          name: 'Intent group 2',
          keywords: [
            '"The evolution of smartphones has revolutionized the way we communicate  with new models offering advanced features and technologies every year."',
            '"Mobile apps have become an integral part of our daily lives  from banking to shopping to entertainment  there\'s an app for everything."',
            '"Mobile gaming has seen a surge in popularity with games like Candy Crush and PUBG attracting millions of players worldwide."',
            '"Mobile marketing strategies are essential for businesses in the digital age  reaching customers directly on their smartphones."',
            '"Mobile health apps are transforming healthcare  allowing users to monitor their health and access medical services remotely."',
            '"Mobile operating systems like Android and iOS are the backbone of smartphones  powering the apps and features we use daily."',
          ],
          categoryDistribution: {
            totalCount: 81,
            totalUniqueCount: 33,
            distribution: [
              {
                name: 'Internet & Telecom',
                percentage: 12.345679012345679,
                count: 10,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless',
                percentage: 9.876543209876543,
                count: 8,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                percentage: 7.407407407407407,
                count: 6,
              },
              {
                name: 'News',
                percentage: 7.407407407407407,
                count: 6,
              },
              {
                name: 'News/Technology News',
                percentage: 7.407407407407407,
                count: 6,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                percentage: 6.172839506172839,
                count: 5,
              },
              {
                name: 'Online Communities',
                percentage: 3.7037037037037037,
                count: 3,
              },
              {
                name: 'Computers & Electronics',
                percentage: 3.7037037037037037,
                count: 3,
              },
              {
                name: 'Arts & Entertainment/TV & Video',
                percentage: 2.4691358024691357,
                count: 2,
              },
              {
                name: 'Internet & Telecom/Service Providers/Phone Service Providers',
                percentage: 2.4691358024691357,
                count: 2,
              },
              {
                name: 'Computers & Electronics/Software',
                percentage: 2.4691358024691357,
                count: 2,
              },
              {
                name: 'Online Communities/Social Networks',
                percentage: 2.4691358024691357,
                count: 2,
              },
              {
                name: 'Arts & Entertainment',
                percentage: 2.4691358024691357,
                count: 2,
              },
              {
                name: 'Internet & Telecom/Service Providers',
                percentage: 2.4691358024691357,
                count: 2,
              },
              {
                name: 'Computers & Electronics/Software/Operating Systems',
                percentage: 2.4691358024691357,
                count: 2,
              },
              {
                name: 'Arts & Entertainment/TV & Video/Online Video',
                percentage: 2.4691358024691357,
                count: 2,
              },
              {
                name: 'Computers & Electronics/Computer Security/Network Security',
                percentage: 2.4691358024691357,
                count: 2,
              },
              {
                name: 'Online Communities/Dating & Personals',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'Business & Industrial',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'People & Society/Social Sciences/Psychology',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Computer Security/Hacking & Cracking',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'Health',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'People & Society',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'People & Society/Family & Relationships',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'Shopping/Consumer Resources',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Consumer Electronics',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Consumer Electronics/Gadgets & Portable Electronics',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'Shopping',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'People & Society/Social Sciences',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'Internet & Telecom/Service Providers/ISPs',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'Health/Mental Health',
                percentage: 1.2345679012345678,
                count: 1,
              },
              {
                name: 'People & Society/Family & Relationships/Family',
                percentage: 1.2345679012345678,
                count: 1,
              },
            ],
          },
          sentimentDistribution: {
            totalCount: 10,
            positiveCount: 4,
            negativeCount: 6,
            percentPositive: 40,
            percentNegative: 60,
          },
          domainMap: {
            'mirror.co.uk': [
              {
                url: 'https://www.mirror.co.uk/news/uk-news/terrifying-brain-scans-show-how-33898729/',
                language: 'en',
                categories: [
                  {
                    name: 'People & Society',
                    weight: 3.0920885,
                  },
                  {
                    name: 'Health',
                    weight: 1.202276,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.99892336,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 0.99892336,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.99892336,
                  },
                  {
                    name: 'Health/Mental Health',
                    weight: 0.9466036,
                  },
                  {
                    name: 'People & Society/Social Sciences',
                    weight: 0.9233021,
                  },
                  {
                    name: 'People & Society/Social Sciences/Psychology',
                    weight: 0.9233021,
                  },
                  {
                    name: 'People & Society/Family & Relationships/Family',
                    weight: 0.8009765,
                  },
                  {
                    name: 'People & Society/Family & Relationships',
                    weight: 0.8009765,
                  },
                ],
                sentiment: -0.5913927555084229,
                similarity: 0.4864976704120636,
              },
            ],
            'techradar.com': [
              {
                url: 'https://www.techradar.com/pro/want-to-launch-a-super-app-heres-what-you-need-to-know/',
                language: 'en',
                categories: [
                  {
                    name: 'News',
                    weight: 1.1921815,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.0561112,
                  },
                  {
                    name: 'Business & Industrial',
                    weight: 1.0171893,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9999957,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.9999957,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.9659435,
                  },
                ],
                sentiment: 0.4100930690765381,
                similarity: 0.5668521523475647,
              },
              {
                url: 'https://www.techradar.com/pro/the-esim-revolution-how-digital-sims-align-with-todays-mobile-habits/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 3.1254466,
                  },
                  {
                    name: 'News',
                    weight: 1.0543123,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.99958485,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.9993087,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers',
                    weight: 0.9701274,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/Phone Service Providers',
                    weight: 0.9701274,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.96804506,
                  },
                ],
                sentiment: -0.0523977056145668,
                similarity: 0.5291123390197754,
              },
            ],
            'theguardian.com': [
              {
                url: 'https://www.theguardian.com/technology/2014/feb/03/facebook-mobile-desktop-pc-platforms/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 1.9515932,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.7849932,
                  },
                  {
                    name: 'News',
                    weight: 1.1749797,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.99909043,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.99839383,
                  },
                  {
                    name: 'Online Communities',
                    weight: 0.99839383,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.99825007,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Network Security',
                    weight: 0.8999903,
                  },
                ],
                sentiment: -0.5802657008171082,
                similarity: 0.5482934713363647,
              },
              {
                url: 'https://www.theguardian.com/commentisfree/2021/nov/14/its-good-to-talk-unless-youre-a-bt-customer/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 4.072963,
                  },
                  {
                    name: 'News',
                    weight: 1.012991,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.99874914,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.99614173,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/Phone Service Providers',
                    weight: 0.9789939,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers',
                    weight: 0.930083,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/ISPs',
                    weight: 0.930083,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.85171455,
                  },
                ],
                sentiment: -0.539840817451477,
                similarity: 0.49527692794799805,
              },
              {
                url: 'https://www.theguardian.com/education/2013/jan/22/mobile-technology-worksheet/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 1.5272962,
                  },
                  {
                    name: 'News',
                    weight: 1.3770113,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.9917454,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.90982556,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.90982556,
                  },
                  {
                    name: 'Online Communities',
                    weight: 0.84237725,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.84237725,
                  },
                ],
                sentiment: -0.37778139114379883,
                similarity: 0.48094817996025085,
              },
            ],
            'tomsguide.com': [
              {
                url: 'https://www.tomsguide.com/computing/malware-adware/hackers-are-using-these-android-apps-on-the-play-store-to-stage-attacks-delete-them-all-right-now/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 3.9946127,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.9868637,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.9995254,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.9967319,
                  },
                  {
                    name: 'News',
                    weight: 0.9967319,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.9873383,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9873383,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.94674665,
                  },
                  {
                    name: 'Computers & Electronics/Software/Operating Systems',
                    weight: 0.94674665,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Network Security',
                    weight: 0.8806432,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Hacking & Cracking',
                    weight: 0.8083181,
                  },
                ],
                sentiment: -0.47369512915611267,
                similarity: 0.5327554941177368,
              },
              {
                url: 'https://www.tomsguide.com/opinion/i-use-these-3-tricks-with-all-my-tablets-so-they-dont-just-feel-like-a-bigger-phone/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 1.8681362,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.6261305,
                  },
                  {
                    name: 'Shopping',
                    weight: 1.003814,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.99883,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.99883,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.9788968,
                  },
                  {
                    name: 'Computers & Electronics/Software/Operating Systems',
                    weight: 0.9788968,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.97696817,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics',
                    weight: 0.88781005,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics/Gadgets & Portable Electronics',
                    weight: 0.88781005,
                  },
                ],
                sentiment: 0.6462688446044922,
                similarity: 0.48469579219818115,
              },
            ],
            'viki.com': [
              {
                url: 'https://www.viki.com/videos/1232653v/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 1.2384591,
                  },
                  {
                    name: 'Online Communities',
                    weight: 1.0053289,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 0.99885714,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.99885714,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.99885714,
                  },
                  {
                    name: 'Online Communities/Dating & Personals',
                    weight: 0.97070104,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video',
                    weight: 0.96563196,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/Online Video',
                    weight: 0.96563196,
                  },
                ],
                sentiment: 0.7809216976165771,
                similarity: 0.5147662162780762,
              },
              {
                url: 'https://www.viki.com/videos/1249281v-step-by-step-love-episode-12/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 0.9998777,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9998777,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.9998777,
                  },
                  {
                    name: 'Arts & Entertainment',
                    weight: 0.97279185,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video',
                    weight: 0.80201477,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/Online Video',
                    weight: 0.80201477,
                  },
                ],
                sentiment: 0.7962448000907898,
                similarity: 0.5045294761657715,
              },
            ],
          },
        },
        {
          name: 'Intent group 3',
          keywords: [
            '"With the increasing use of smartphones  mobile security has become a major concern  protecting personal data from cyber threats is crucial."',
            '"Mobile device repair services are essential for fixing common issues like cracked screens  battery problems  and software glitches."',
          ],
          categoryDistribution: {
            totalCount: 86,
            totalUniqueCount: 20,
            distribution: [
              {
                name: 'Computers & Electronics',
                percentage: 11.627906976744185,
                count: 10,
              },
              {
                name: 'Internet & Telecom',
                percentage: 10.465116279069768,
                count: 9,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                percentage: 10.465116279069768,
                count: 9,
              },
              {
                name: 'News',
                percentage: 6.976744186046512,
                count: 6,
              },
              {
                name: 'Computers & Electronics/Computer Security/Network Security',
                percentage: 6.976744186046512,
                count: 6,
              },
              {
                name: 'Computers & Electronics/Computer Security/Hacking & Cracking',
                percentage: 6.976744186046512,
                count: 6,
              },
              {
                name: 'News/Technology News',
                percentage: 6.976744186046512,
                count: 6,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless',
                percentage: 6.976744186046512,
                count: 6,
              },
              {
                name: 'Reference/General Reference',
                percentage: 3.488372093023256,
                count: 3,
              },
              {
                name: 'Computers & Electronics/Software',
                percentage: 3.488372093023256,
                count: 3,
              },
              {
                name: 'Reference',
                percentage: 3.488372093023256,
                count: 3,
              },
              {
                name: 'Computers & Electronics/Computer Security',
                percentage: 3.488372093023256,
                count: 3,
              },
              {
                name: 'Reference/General Reference/How-To, DIY & Expert Content',
                percentage: 3.488372093023256,
                count: 3,
              },
              {
                name: 'Computers & Electronics/Software/Operating Systems',
                percentage: 3.488372093023256,
                count: 3,
              },
              {
                name: 'Computers & Electronics/Computer Security/Antivirus & Malware',
                percentage: 2.3255813953488373,
                count: 2,
              },
              {
                name: 'Shopping/Consumer Resources/Identity Theft Protection',
                percentage: 2.3255813953488373,
                count: 2,
              },
              {
                name: 'Shopping',
                percentage: 2.3255813953488373,
                count: 2,
              },
              {
                name: 'Shopping/Consumer Resources',
                percentage: 2.3255813953488373,
                count: 2,
              },
              {
                name: 'Law & Government',
                percentage: 1.1627906976744187,
                count: 1,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                percentage: 1.1627906976744187,
                count: 1,
              },
            ],
          },
          sentimentDistribution: {
            totalCount: 10,
            positiveCount: 0,
            negativeCount: 10,
            percentPositive: 0,
            percentNegative: 100,
          },
          domainMap: {
            'buzzfeed.com': [
              {
                url: 'https://www.buzzfeed.com/kimeldard/8-outstanding-ways-to-maintain-your-cyber-security-2gvvx/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 3.7386649,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Network Security',
                    weight: 0.9812339,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Hacking & Cracking',
                    weight: 0.96068627,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.8708714,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Identity Theft Protection',
                    weight: 0.8708714,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.8708714,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security',
                    weight: 0.86876076,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Antivirus & Malware',
                    weight: 0.86876076,
                  },
                ],
                sentiment: -0.4725552499294281,
                similarity: 0.5423142910003662,
              },
            ],
            'digitaltrends.com': [
              {
                url: 'https://www.digitaltrends.com/computing/most-common-ways-to-break-laptops/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 2.6261506,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.9981223,
                  },
                  {
                    name: 'News',
                    weight: 0.9981223,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 0.9751193,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9751193,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.9751193,
                  },
                ],
                sentiment: -0.541745126247406,
                similarity: 0.5480916500091553,
              },
            ],
            'echo-news.co.uk': [
              {
                url: 'https://www.echo-news.co.uk/news/24770044.five-mistakes-putting-smartphone-security-risk/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 3.1975484,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.6146014,
                  },
                  {
                    name: 'Reference',
                    weight: 1.0745965,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.9993736,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Network Security',
                    weight: 0.9760809,
                  },
                  {
                    name: 'Reference/General Reference/How-To, DIY & Expert Content',
                    weight: 0.83563775,
                  },
                  {
                    name: 'Reference/General Reference',
                    weight: 0.83563775,
                  },
                ],
                sentiment: -0.5289792418479919,
                similarity: 0.6033114194869995,
              },
            ],
            'mirror.co.uk': [
              {
                url: 'https://www.mirror.co.uk/tech/google-android-update-warning-fix-34072453/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 3.0115538,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.0310578,
                  },
                  {
                    name: 'News',
                    weight: 1.0057089,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.9997347,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.9996414,
                  },
                  {
                    name: 'Computers & Electronics/Software/Operating Systems',
                    weight: 0.9996414,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Hacking & Cracking',
                    weight: 0.9980324,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9868679,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.9868679,
                  },
                ],
                sentiment: -0.5442841649055481,
                similarity: 0.5686901211738586,
              },
              {
                url: 'https://www.mirror.co.uk/tech/google-android-update-warning-fix-34072453.amp/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 3.0115538,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.0310578,
                  },
                  {
                    name: 'News',
                    weight: 1.0057089,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.9997347,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.9996414,
                  },
                  {
                    name: 'Computers & Electronics/Software/Operating Systems',
                    weight: 0.9996414,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Hacking & Cracking',
                    weight: 0.9980324,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.9868679,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9868679,
                  },
                ],
                sentiment: -0.5442841649055481,
                similarity: 0.5686901211738586,
              },
            ],
            'newsshopper.co.uk': [
              {
                url: 'https://www.newsshopper.co.uk/news/national/uk-today/24768167.five-mistakes-putting-smartphone-security-risk/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 3.1975484,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.6146014,
                  },
                  {
                    name: 'Reference',
                    weight: 1.0745965,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.9993736,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Network Security',
                    weight: 0.9760809,
                  },
                  {
                    name: 'Reference/General Reference',
                    weight: 0.83563775,
                  },
                  {
                    name: 'Reference/General Reference/How-To, DIY & Expert Content',
                    weight: 0.83563775,
                  },
                ],
                sentiment: -0.5289792418479919,
                similarity: 0.6033114194869995,
              },
            ],
            'oxfordmail.co.uk': [
              {
                url: 'https://www.oxfordmail.co.uk/news/24770119.five-mistakes-putting-smartphone-security-risk/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 3.1975482,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.6146015,
                  },
                  {
                    name: 'Reference',
                    weight: 1.0745966,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.9993736,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Network Security',
                    weight: 0.9760809,
                  },
                  {
                    name: 'Reference/General Reference/How-To, DIY & Expert Content',
                    weight: 0.8356378,
                  },
                  {
                    name: 'Reference/General Reference',
                    weight: 0.8356378,
                  },
                ],
                sentiment: -0.5289792418479919,
                similarity: 0.6033114194869995,
              },
            ],
            'techradar.com': [
              {
                url: 'https://www.techradar.com/pro/securing-your-smartphone-vital-steps-to-protect-your-digital-assets/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 2.9759278,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.0256615,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Network Security',
                    weight: 0.9994267,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.99501073,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.99501073,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.98972243,
                  },
                  {
                    name: 'News',
                    weight: 0.98972243,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.9665943,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Identity Theft Protection',
                    weight: 0.9665943,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.9665943,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security',
                    weight: 0.95101947,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Hacking & Cracking',
                    weight: 0.95101947,
                  },
                ],
                sentiment: -0.5521315932273865,
                similarity: 0.6367915868759155,
              },
            ],
            'theguardian.com': [
              {
                url: 'https://www.theguardian.com/technology/2023/jun/23/turn-your-phone-off-every-night-for-five-minutes-australian-pm-tells-residents/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 3.1686094,
                  },
                  {
                    name: 'News',
                    weight: 1.9577987,
                  },
                  {
                    name: 'Law & Government',
                    weight: 1.6661427,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.99950826,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Network Security',
                    weight: 0.9938617,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 0.9804459,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9804459,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.9804459,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security',
                    weight: 0.8777156,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Hacking & Cracking',
                    weight: 0.8777156,
                  },
                ],
                sentiment: -0.5353084802627563,
                similarity: 0.592022180557251,
              },
            ],
            'thesun.co.uk': [
              {
                url: 'https://www.thesun.co.uk/tech/31514655/android-warning-fakecall-malware-hijack-bank-calls/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 3.7853804,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.93744,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.99931204,
                  },
                  {
                    name: 'News',
                    weight: 0.99931204,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Hacking & Cracking',
                    weight: 0.99688715,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.99687636,
                  },
                  {
                    name: 'Computers & Electronics/Software/Operating Systems',
                    weight: 0.99687636,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.9889903,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.94844973,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.94844973,
                  },
                  {
                    name: 'Computers & Electronics/Computer Security/Antivirus & Malware',
                    weight: 0.9461702,
                  },
                ],
                sentiment: -0.5417806506156921,
                similarity: 0.546511709690094,
              },
            ],
          },
        },
        {
          name: 'Intent group 4',
          keywords: [
            '"Mobile photography has been enhanced by the advanced camera features in smartphones  making professional-quality photos accessible to everyone."',
            '"Mobile device accessories like cases  headphones  and chargers enhance the usability and functionality of our smartphones."',
            '"Mobile virtual reality offers immersive experiences through VR headsets paired with smartphones."',
          ],
          categoryDistribution: {
            totalCount: 68,
            totalUniqueCount: 29,
            distribution: [
              {
                name: 'Computers & Electronics',
                percentage: 13.235294117647058,
                count: 9,
              },
              {
                name: 'Computers & Electronics/Software/Operating Systems',
                percentage: 5.882352941176471,
                count: 4,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless',
                percentage: 5.882352941176471,
                count: 4,
              },
              {
                name: 'Shopping',
                percentage: 5.882352941176471,
                count: 4,
              },
              {
                name: 'Internet & Telecom',
                percentage: 5.882352941176471,
                count: 4,
              },
              {
                name: 'Computers & Electronics/Software',
                percentage: 5.882352941176471,
                count: 4,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                percentage: 5.882352941176471,
                count: 4,
              },
              {
                name: 'Shopping/Consumer Resources',
                percentage: 5.882352941176471,
                count: 4,
              },
              {
                name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                percentage: 5.882352941176471,
                count: 4,
              },
              {
                name: 'Computers & Electronics/Consumer Electronics',
                percentage: 5.882352941176471,
                count: 4,
              },
              {
                name: 'Computers & Electronics/Consumer Electronics/Virtual Reality Devices',
                percentage: 4.411764705882353,
                count: 3,
              },
              {
                name: 'News',
                percentage: 2.9411764705882355,
                count: 2,
              },
              {
                name: 'News/Technology News',
                percentage: 2.9411764705882355,
                count: 2,
              },
              {
                name: 'Science/Engineering & Technology/Augmented & Virtual Reality',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Reference/General Reference/How-To, DIY & Expert Content',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Consumer Electronics/TV & Video Equipment',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Visual Art & Design/Photographic & Digital Arts',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Arts & Entertainment',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless/Mobile & Wireless Accessories',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Consumer Electronics/Camera & Photo Equipment',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Travel & Transportation',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Visual Art & Design',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Internet & Telecom/Service Providers',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Science/Engineering & Technology',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Internet & Telecom/Service Providers/Phone Service Providers',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Consumer Electronics/Game Systems & Consoles',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Reference',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Science',
                percentage: 1.4705882352941178,
                count: 1,
              },
              {
                name: 'Reference/General Reference',
                percentage: 1.4705882352941178,
                count: 1,
              },
            ],
          },
          sentimentDistribution: {
            totalCount: 10,
            positiveCount: 10,
            negativeCount: 0,
            percentPositive: 100,
            percentNegative: 0,
          },
          domainMap: {
            'ebay.co.uk': [
              {
                url: 'https://www.ebay.co.uk/itm/286126892180/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 1.0600184,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics',
                    weight: 0.9996024,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics/Virtual Reality Devices',
                    weight: 0.9996024,
                  },
                ],
                sentiment: 0.6805642247200012,
                similarity: 0.5159132480621338,
              },
            ],
            'guidingtech.com': [
              {
                url: 'https://www.guidingtech.com/smartphone-vs-dslr-camera/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 1.193076,
                  },
                  {
                    name: 'Arts & Entertainment',
                    weight: 1.0887864,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics',
                    weight: 0.9984592,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics/Camera & Photo Equipment',
                    weight: 0.9984592,
                  },
                  {
                    name: 'Arts & Entertainment/Visual Art & Design',
                    weight: 0.9979347,
                  },
                  {
                    name: 'Arts & Entertainment/Visual Art & Design/Photographic & Digital Arts',
                    weight: 0.9979347,
                  },
                ],
                sentiment: 0.35122671723365784,
                similarity: 0.5222209692001343,
              },
            ],
            'techradar.com': [
              {
                url: 'https://www.techradar.com/news/best-ar-apps/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 1.9524245,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics',
                    weight: 0.98105836,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics/Virtual Reality Devices',
                    weight: 0.98105836,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 0.9711335,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.965024,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.965024,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.9632157,
                  },
                  {
                    name: 'Computers & Electronics/Software/Operating Systems',
                    weight: 0.9632157,
                  },
                  {
                    name: 'Science',
                    weight: 0.92826116,
                  },
                  {
                    name: 'Science/Engineering & Technology',
                    weight: 0.92826116,
                  },
                  {
                    name: 'Science/Engineering & Technology/Augmented & Virtual Reality',
                    weight: 0.92826116,
                  },
                  {
                    name: 'News',
                    weight: 0.81549025,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.8145216,
                  },
                ],
                sentiment: 0.3969191014766693,
                similarity: 0.5701542496681213,
              },
              {
                url: 'https://www.techradar.com/reviews/vodafone-smart-v10/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 1.8222721,
                  },
                  {
                    name: 'Shopping',
                    weight: 1.2753508,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.99968255,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.99968255,
                  },
                  {
                    name: 'Computers & Electronics',
                    weight: 0.99856985,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.9964714,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.9964714,
                  },
                  {
                    name: 'Computers & Electronics/Software/Operating Systems',
                    weight: 0.99521893,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.99521893,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/Phone Service Providers',
                    weight: 0.8110611,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers',
                    weight: 0.8110611,
                  },
                ],
                sentiment: 0.6340972185134888,
                similarity: 0.48638445138931274,
              },
              {
                url: 'https://www.techradar.com/how-to/how-to-turn-your-smartphone-into-a-home-cinema/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 2.1183002,
                  },
                  {
                    name: 'Reference',
                    weight: 1.4945227,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics/TV & Video Equipment',
                    weight: 0.9983082,
                  },
                  {
                    name: 'Reference/General Reference/How-To, DIY & Expert Content',
                    weight: 0.99270916,
                  },
                  {
                    name: 'Reference/General Reference',
                    weight: 0.99270916,
                  },
                ],
                sentiment: 0.2452022284269333,
                similarity: 0.4857252538204193,
              },
            ],
            'telegraph.co.uk': [
              {
                url: 'https://www.telegraph.co.uk/technology/0/best-virtual-reality-headsets-can-buy/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 1.0534307,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics',
                    weight: 0.999619,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics/Virtual Reality Devices',
                    weight: 0.999619,
                  },
                ],
                sentiment: 0.6283891797065735,
                similarity: 0.5308057069778442,
              },
            ],
            'tomsguide.com': [
              {
                url: 'https://www.tomsguide.com/us/huawei-p30-pro,review-6317.html/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 1.738314,
                  },
                  {
                    name: 'Computers & Electronics/Software/Operating Systems',
                    weight: 0.99461824,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.99461824,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 0.9931987,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9931987,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.9931987,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.9922497,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.9922497,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.9922497,
                  },
                  {
                    name: 'News',
                    weight: 0.98920417,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.9876302,
                  },
                ],
                sentiment: 0.4308265149593353,
                similarity: 0.5076814889907837,
              },
              {
                url: 'https://www.tomsguide.com/news/rip-backbone-one-i-just-saw-the-ultimate-mobile-game-controller-at-ces-2024/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 3.0333407,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.7990749,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.9983784,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.9983784,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.9983784,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile & Wireless Accessories',
                    weight: 0.98373914,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics/Game Systems & Consoles',
                    weight: 0.94264835,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.8441954,
                  },
                  {
                    name: 'Computers & Electronics/Software/Operating Systems',
                    weight: 0.8441954,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.81533575,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.81533575,
                  },
                ],
                sentiment: 0.7084131836891174,
                similarity: 0.5074760913848877,
              },
            ],
            'travelandleisure.com': [
              {
                url: 'https://www.travelandleisure.com/best-gps-navigators-8422952/',
                language: 'en',
                categories: [
                  {
                    name: 'Travel & Transportation',
                    weight: 1.7565014,
                  },
                  {
                    name: 'Computers & Electronics',
                    weight: 1.04426,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.99658465,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.99658465,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.99658465,
                  },
                ],
                sentiment: 0.6448726654052734,
                similarity: 0.48602378368377686,
              },
            ],
            'uhdpaper.com': [
              {
                url: 'https://www.uhdpaper.com/2024/10/1222b-joker-2-movie-joker-harley-quinn.html/',
                language: 'en',
                categories: [],
                sentiment: 0.7382158637046814,
                similarity: 0.4850950241088867,
              },
            ],
          },
        },
        {
          name: 'Intent group 5',
          keywords: [
            '"Mobile networks connect us to the internet  enabling browsing  streaming  and social media use on the go."',
            '"Mobile data plans offer internet access on the go  with various options for speed  data limits  and pricing."',
          ],
          categoryDistribution: {
            totalCount: 85,
            totalUniqueCount: 33,
            distribution: [
              {
                name: 'Internet & Telecom',
                percentage: 11.764705882352942,
                count: 10,
              },
              {
                name: 'News',
                percentage: 8.235294117647058,
                count: 7,
              },
              {
                name: 'Internet & Telecom/Service Providers/ISPs',
                percentage: 8.235294117647058,
                count: 7,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                percentage: 5.882352941176471,
                count: 5,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless',
                percentage: 5.882352941176471,
                count: 5,
              },
              {
                name: 'News/Technology News',
                percentage: 4.705882352941177,
                count: 4,
              },
              {
                name: 'Computers & Electronics',
                percentage: 4.705882352941177,
                count: 4,
              },
              {
                name: 'Internet & Telecom/Service Providers',
                percentage: 3.5294117647058822,
                count: 3,
              },
              {
                name: 'News/Business News',
                percentage: 3.5294117647058822,
                count: 3,
              },
              {
                name: 'Shopping',
                percentage: 3.5294117647058822,
                count: 3,
              },
              {
                name: 'Internet & Telecom/Service Providers/Phone Service Providers',
                percentage: 3.5294117647058822,
                count: 3,
              },
              {
                name: 'Shopping/Consumer Resources',
                percentage: 3.5294117647058822,
                count: 3,
              },
              {
                name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                percentage: 3.5294117647058822,
                count: 3,
              },
              {
                name: 'News/Business News/Company News',
                percentage: 3.5294117647058822,
                count: 3,
              },
              {
                name: 'Reference/Technical Reference',
                percentage: 2.3529411764705883,
                count: 2,
              },
              {
                name: 'Reference',
                percentage: 2.3529411764705883,
                count: 2,
              },
              {
                name: 'Computers & Electronics/Software',
                percentage: 2.3529411764705883,
                count: 2,
              },
              {
                name: 'Computers & Electronics/Networking',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Internet & Telecom/Service Providers/Cable & Satellite Providers',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Software/Operating Systems',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Online Communities',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Reference/General Reference/How-To, DIY & Expert Content',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Travel & Transportation/Transportation/Air Travel',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Travel & Transportation/Transportation',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Travel & Transportation',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Internet & Telecom/Communications Equipment',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Computer Hardware/Laptops & Notebooks',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/TV & Video/Online Video',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Online Communities/Social Networks',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Computer Hardware',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/TV & Video',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Arts & Entertainment',
                percentage: 1.1764705882352942,
                count: 1,
              },
              {
                name: 'Reference/General Reference',
                percentage: 1.1764705882352942,
                count: 1,
              },
            ],
          },
          sentimentDistribution: {
            totalCount: 10,
            positiveCount: 2,
            negativeCount: 8,
            percentPositive: 20,
            percentNegative: 80,
          },
          domainMap: {
            'cnn.com': [
              {
                url: 'https://edition.cnn.com/markets/stocks/NOK/',
                language: 'en',
                categories: [
                  {
                    name: 'Computers & Electronics',
                    weight: 2.9360929,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.6464361,
                  },
                  {
                    name: 'News',
                    weight: 0.9971687,
                  },
                  {
                    name: 'News/Business News/Company News',
                    weight: 0.9971687,
                  },
                  {
                    name: 'News/Business News',
                    weight: 0.9971687,
                  },
                  {
                    name: 'Computers & Electronics/Networking',
                    weight: 0.9929853,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.9843961,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.85264397,
                  },
                ],
                sentiment: 0.5903292298316956,
                similarity: 0.5671936273574829,
              },
              {
                url: 'https://edition.cnn.com/2022/09/03/tech/inflight-wifi-technology/index.html/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 2.2270648,
                  },
                  {
                    name: 'Travel & Transportation',
                    weight: 1.0855142,
                  },
                  {
                    name: 'News',
                    weight: 1.0465169,
                  },
                  {
                    name: 'Travel & Transportation/Transportation/Air Travel',
                    weight: 0.9992423,
                  },
                  {
                    name: 'Travel & Transportation/Transportation',
                    weight: 0.9992423,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/ISPs',
                    weight: 0.99332124,
                  },
                  {
                    name: 'News/Business News',
                    weight: 0.93081665,
                  },
                  {
                    name: 'News/Business News/Company News',
                    weight: 0.93081665,
                  },
                ],
                sentiment: -0.49762579798698425,
                similarity: 0.5643838047981262,
              },
            ],
            'dailymail.co.uk': [
              {
                url: 'https://www.dailymail.co.uk/sciencetech/article-2830284/Looking-upgrade-phone-Interactive-map-reveals-mobile-coverage-UK-tells-network-best-area.html/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 4.05108,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/Phone Service Providers',
                    weight: 0.99930084,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers',
                    weight: 0.99918896,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/ISPs',
                    weight: 0.99918896,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.9966208,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9940322,
                  },
                  {
                    name: 'News',
                    weight: 0.89637727,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.8732999,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.8230227,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.8230227,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.8230227,
                  },
                ],
                sentiment: -0.34929218888282776,
                similarity: 0.5533028244972229,
              },
              {
                url: 'https://www.dailymail.co.uk/sciencetech/article-12268091/Report-compares-T-Mobile-Verizon-T-reveal-best-service-US.html/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 4.1827316,
                  },
                  {
                    name: 'News',
                    weight: 1.3289375,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/Phone Service Providers',
                    weight: 0.99993265,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.99538404,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.95472914,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/ISPs',
                    weight: 0.90338916,
                  },
                  {
                    name: 'News/Business News',
                    weight: 0.81110305,
                  },
                  {
                    name: 'News/Business News/Company News',
                    weight: 0.81110305,
                  },
                ],
                sentiment: -0.10262257605791092,
                similarity: 0.5476473569869995,
              },
            ],
            'digitaltrends.com': [
              {
                url: 'https://www.digitaltrends.com/computing/best-5g-laptops/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 1.3110907,
                  },
                  {
                    name: 'Computers & Electronics',
                    weight: 1.0751435,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.9994666,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.9994666,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.9994666,
                  },
                  {
                    name: 'Computers & Electronics/Computer Hardware',
                    weight: 0.9978327,
                  },
                  {
                    name: 'Computers & Electronics/Computer Hardware/Laptops & Notebooks',
                    weight: 0.9978327,
                  },
                  {
                    name: 'News',
                    weight: 0.91125655,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.91125655,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/ISPs',
                    weight: 0.8287756,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers',
                    weight: 0.8287756,
                  },
                ],
                sentiment: 0.42939019203186035,
                similarity: 0.6130468845367432,
              },
            ],
            'ispreview.co.uk': [
              {
                url: 'https://www.ispreview.co.uk/broadband_mobile.php/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 4.110337,
                  },
                  {
                    name: 'Computers & Electronics',
                    weight: 0.9610023,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/ISPs',
                    weight: 0.91723496,
                  },
                  {
                    name: 'Reference/Technical Reference',
                    weight: 0.90228367,
                  },
                  {
                    name: 'Reference',
                    weight: 0.90228367,
                  },
                  {
                    name: 'Internet & Telecom/Communications Equipment',
                    weight: 0.8010303,
                  },
                ],
                sentiment: -0.3588879406452179,
                similarity: 0.5802698135375977,
              },
            ],
            'techradar.com': [
              {
                url: 'https://www.techradar.com/phones/android/how-to-hotspot-from-an-android-phone/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 3.0493076,
                  },
                  {
                    name: 'Computers & Electronics',
                    weight: 2.8988893,
                  },
                  {
                    name: 'Reference',
                    weight: 1.8478305,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.98429835,
                  },
                  {
                    name: 'Reference/General Reference',
                    weight: 0.97039235,
                  },
                  {
                    name: 'Reference/General Reference/How-To, DIY & Expert Content',
                    weight: 0.97039235,
                  },
                  {
                    name: 'Computers & Electronics/Software/Operating Systems',
                    weight: 0.9162255,
                  },
                  {
                    name: 'Computers & Electronics/Software',
                    weight: 0.9162255,
                  },
                  {
                    name: 'Reference/Technical Reference',
                    weight: 0.8774382,
                  },
                ],
                sentiment: -0.08365005999803543,
                similarity: 0.5492558479309082,
              },
            ],
            'theguardian.com': [
              {
                url: 'https://www.theguardian.com/commentisfree/2021/nov/14/its-good-to-talk-unless-youre-a-bt-customer/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 4.072963,
                  },
                  {
                    name: 'News',
                    weight: 1.012991,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.99874914,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.99614173,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/Phone Service Providers',
                    weight: 0.9789939,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers',
                    weight: 0.930083,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/ISPs',
                    weight: 0.930083,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.85171455,
                  },
                ],
                sentiment: -0.539840817451477,
                similarity: 0.5687554478645325,
              },
              {
                url: 'https://www.theguardian.com/education/2013/jan/22/mobile-technology-worksheet/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 1.5272962,
                  },
                  {
                    name: 'News',
                    weight: 1.3770113,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.9917454,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.90982556,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Phones',
                    weight: 0.90982556,
                  },
                  {
                    name: 'Online Communities',
                    weight: 0.84237725,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.84237725,
                  },
                ],
                sentiment: -0.37778139114379883,
                similarity: 0.5453903675079346,
              },
            ],
            'thetimes.com': [
              {
                url: 'https://www.thetimes.com/money-mentor/broadband/compare-the-best-broadband-deals/',
                language: 'en',
                categories: [
                  {
                    name: 'Internet & Telecom',
                    weight: 2.630685,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/Cable & Satellite Providers',
                    weight: 0.99778277,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/ISPs',
                    weight: 0.98812985,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.97446,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.97446,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.97446,
                  },
                  {
                    name: 'Arts & Entertainment',
                    weight: 0.87889993,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/Online Video',
                    weight: 0.8031065,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video',
                    weight: 0.8031065,
                  },
                ],
                sentiment: -0.20131775736808777,
                similarity: 0.5867682695388794,
              },
            ],
          },
        },
        {
          name: 'Intent group 6',
          keywords: [
            '"Mobile social media apps like Instagram and Snapchat have changed the way we communicate and share our lives."',
          ],
          categoryDistribution: {
            totalCount: 78,
            totalUniqueCount: 45,
            distribution: [
              {
                name: 'Online Communities',
                percentage: 11.538461538461538,
                count: 9,
              },
              {
                name: 'Online Communities/Social Networks',
                percentage: 11.538461538461538,
                count: 9,
              },
              {
                name: 'Online Communities/Photo & Video Sharing/Photo & Image Sharing',
                percentage: 3.8461538461538463,
                count: 3,
              },
              {
                name: 'Arts & Entertainment',
                percentage: 3.8461538461538463,
                count: 3,
              },
              {
                name: 'News',
                percentage: 3.8461538461538463,
                count: 3,
              },
              {
                name: 'People & Society',
                percentage: 2.5641025641025643,
                count: 2,
              },
              {
                name: 'Online Communities/Photo & Video Sharing',
                percentage: 2.5641025641025643,
                count: 2,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless',
                percentage: 2.5641025641025643,
                count: 2,
              },
              {
                name: 'News/Technology News',
                percentage: 2.5641025641025643,
                count: 2,
              },
              {
                name: 'Reference/General Reference/How-To, DIY & Expert Content',
                percentage: 2.5641025641025643,
                count: 2,
              },
              {
                name: 'Reference/General Reference',
                percentage: 2.5641025641025643,
                count: 2,
              },
              {
                name: 'Arts & Entertainment/Online Media/Online Image Galleries',
                percentage: 2.5641025641025643,
                count: 2,
              },
              {
                name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                percentage: 2.5641025641025643,
                count: 2,
              },
              {
                name: 'Internet & Telecom',
                percentage: 2.5641025641025643,
                count: 2,
              },
              {
                name: 'Arts & Entertainment/Online Media',
                percentage: 2.5641025641025643,
                count: 2,
              },
              {
                name: 'Reference',
                percentage: 2.5641025641025643,
                count: 2,
              },
              {
                name: 'Arts & Entertainment/Offbeat',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'News/Sports News',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'People & Society/Social Sciences/Psychology',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'People & Society/Social Sciences',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Beauty & Fitness/Face & Body Care',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Beauty & Fitness/Fashion & Style',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Shopping/Consumer Resources',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Health/Medical Literature & Resources',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Online Communities/Blogging Resources & Services',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Music & Audio',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Internet & Telecom/Email & Messaging',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/TV & Video',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Sports/Team Sports',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Sports/Team Sports/Soccer',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Music & Audio/Radio',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Computers & Electronics',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Shopping',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Business & Industrial/Advertising & Marketing/Marketing',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Health',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Business & Industrial',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Business & Industrial/Advertising & Marketing',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Shopping/Consumer Resources/Customer Services',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Sports',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Beauty & Fitness/Face & Body Care/Make-Up & Cosmetics',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Health/Mental Health/Depression',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Beauty & Fitness',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Internet & Telecom/Email & Messaging/Text & Instant Messaging',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/TV & Video/Online Video',
                percentage: 1.2820512820512822,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/TV & Video/TV Networks & Stations',
                percentage: 1.2820512820512822,
                count: 1,
              },
            ],
          },
          sentimentDistribution: {
            totalCount: 10,
            positiveCount: 4,
            negativeCount: 6,
            percentPositive: 40,
            percentNegative: 60,
          },
          domainMap: {
            'dailymail.co.uk': [
              {
                url: 'https://www.dailymail.co.uk/health/article-10238083/Harvard-study-finds-40s-use-Snapchat-Instagram-TikTok-likely-feel-down.html/',
                language: 'en',
                categories: [
                  {
                    name: 'Health',
                    weight: 3.5647757,
                  },
                  {
                    name: 'People & Society',
                    weight: 1.0683557,
                  },
                  {
                    name: 'Online Communities',
                    weight: 1.0498894,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.99948895,
                  },
                  {
                    name: 'People & Society/Social Sciences',
                    weight: 0.99293536,
                  },
                  {
                    name: 'People & Society/Social Sciences/Psychology',
                    weight: 0.99293536,
                  },
                  {
                    name: 'Health/Mental Health/Depression',
                    weight: 0.9728318,
                  },
                  {
                    name: 'Health/Medical Literature & Resources',
                    weight: 0.84938794,
                  },
                ],
                sentiment: -0.6011760234832764,
                similarity: 0.6102218627929688,
              },
              {
                url: 'https://www.dailymail.co.uk/femail/article-8832703/twitter-users-share-viral-started-vs-going-meme.html/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 4.3598223,
                  },
                  {
                    name: 'Online Communities',
                    weight: 1.9874935,
                  },
                  {
                    name: 'Online Communities/Blogging Resources & Services',
                    weight: 0.99518436,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.9923091,
                  },
                  {
                    name: 'Arts & Entertainment/Online Media',
                    weight: 0.9054166,
                  },
                  {
                    name: 'Arts & Entertainment/Online Media/Online Image Galleries',
                    weight: 0.9054166,
                  },
                  {
                    name: 'Arts & Entertainment/Offbeat',
                    weight: 0.8400213,
                  },
                ],
                sentiment: -0.09022635221481323,
                similarity: 0.5749356746673584,
              },
            ],
            'digitaltrends.com': [
              {
                url: 'https://www.digitaltrends.com/social-media/how-to-use-snapchat/',
                language: 'en',
                categories: [
                  {
                    name: 'Online Communities',
                    weight: 2.5793397,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.2171619,
                  },
                  {
                    name: 'Reference',
                    weight: 1.0386815,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.9994966,
                  },
                  {
                    name: 'Online Communities/Photo & Video Sharing/Photo & Image Sharing',
                    weight: 0.9991597,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9982437,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.9982437,
                  },
                  {
                    name: 'Reference/General Reference',
                    weight: 0.9969656,
                  },
                  {
                    name: 'Reference/General Reference/How-To, DIY & Expert Content',
                    weight: 0.9969656,
                  },
                ],
                sentiment: 0.0595083050429821,
                similarity: 0.5806339979171753,
              },
              {
                url: 'https://www.digitaltrends.com/web/filter-bubble-algorithm-social-media/',
                language: 'en',
                categories: [
                  {
                    name: 'News',
                    weight: 1.2237442,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.9999192,
                  },
                  {
                    name: 'Online Communities',
                    weight: 0.9999192,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.994756,
                  },
                ],
                sentiment: -0.3843238353729248,
                similarity: 0.577049732208252,
              },
            ],
            'guidingtech.com': [
              {
                url: 'https://www.guidingtech.com/how-to-hide-story-from-someone-on-snapchat/',
                language: 'en',
                categories: [
                  {
                    name: 'Online Communities',
                    weight: 2.0440135,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 2.0098631,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons',
                    weight: 0.9999851,
                  },
                  {
                    name: 'Internet & Telecom/Mobile & Wireless',
                    weight: 0.9999851,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.9961212,
                  },
                  {
                    name: 'Reference/General Reference/How-To, DIY & Expert Content',
                    weight: 0.97727066,
                  },
                  {
                    name: 'Reference',
                    weight: 0.97727066,
                  },
                  {
                    name: 'Reference/General Reference',
                    weight: 0.97727066,
                  },
                  {
                    name: 'Online Communities/Photo & Video Sharing/Photo & Image Sharing',
                    weight: 0.9680435,
                  },
                  {
                    name: 'Online Communities/Photo & Video Sharing',
                    weight: 0.9680435,
                  },
                  {
                    name: 'Internet & Telecom/Email & Messaging',
                    weight: 0.93107986,
                  },
                  {
                    name: 'Internet & Telecom/Email & Messaging/Text & Instant Messaging',
                    weight: 0.93107986,
                  },
                ],
                sentiment: -0.33439937233924866,
                similarity: 0.5766769051551819,
              },
            ],
            'huffpost.com': [
              {
                url: 'https://www.huffpost.com/entry/social-media-makes-you-socially-awkward_n_5512749/',
                language: 'en',
                categories: [
                  {
                    name: 'News',
                    weight: 1.1528596,
                  },
                  {
                    name: 'People & Society',
                    weight: 1.1442581,
                  },
                  {
                    name: 'Online Communities',
                    weight: 0.99767715,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.99767715,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.9807155,
                  },
                ],
                sentiment: -0.3011424243450165,
                similarity: 0.6186931133270264,
              },
            ],
            'hulldailymail.co.uk': [
              {
                url: 'https://www.hulldailymail.co.uk/news/hull-east-yorkshire-news/myspace-old-profile-account-find-4820837/',
                language: 'en',
                categories: [
                  {
                    name: 'Online Communities',
                    weight: 1.850258,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.9999856,
                  },
                ],
                sentiment: -0.26772451400756836,
                similarity: 0.7216030359268188,
              },
            ],
            'mirror.co.uk': [
              {
                url: 'https://www.mirror.co.uk/news/weird-news/dove-praised-powerful-reverse-selfie-24009349/',
                language: 'en',
                categories: [
                  {
                    name: 'Beauty & Fitness',
                    weight: 2.2564447,
                  },
                  {
                    name: 'Arts & Entertainment',
                    weight: 2.152193,
                  },
                  {
                    name: 'Online Communities',
                    weight: 1.9187227,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.99980825,
                  },
                  {
                    name: 'Arts & Entertainment/Online Media/Online Image Galleries',
                    weight: 0.9785579,
                  },
                  {
                    name: 'Arts & Entertainment/Online Media',
                    weight: 0.9785579,
                  },
                  {
                    name: 'Beauty & Fitness/Face & Body Care/Make-Up & Cosmetics',
                    weight: 0.92025423,
                  },
                  {
                    name: 'Beauty & Fitness/Face & Body Care',
                    weight: 0.92025423,
                  },
                  {
                    name: 'Online Communities/Photo & Video Sharing/Photo & Image Sharing',
                    weight: 0.9189145,
                  },
                  {
                    name: 'Online Communities/Photo & Video Sharing',
                    weight: 0.9189145,
                  },
                  {
                    name: 'Beauty & Fitness/Fashion & Style',
                    weight: 0.81883776,
                  },
                ],
                sentiment: 0.033633068203926086,
                similarity: 0.6328596472740173,
              },
            ],
            'theguardian.com': [
              {
                url: 'https://www.theguardian.com/media-network/2015/may/21/customer-complaints-social-media-rise/',
                language: 'en',
                categories: [
                  {
                    name: 'Business & Industrial',
                    weight: 1.3191166,
                  },
                  {
                    name: 'Computers & Electronics',
                    weight: 1.097122,
                  },
                  {
                    name: 'Business & Industrial/Advertising & Marketing/Marketing',
                    weight: 0.9996865,
                  },
                  {
                    name: 'Business & Industrial/Advertising & Marketing',
                    weight: 0.9996865,
                  },
                  {
                    name: 'Online Communities/Social Networks',
                    weight: 0.99641883,
                  },
                  {
                    name: 'Online Communities',
                    weight: 0.99641883,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.8377026,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.8377026,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Customer Services',
                    weight: 0.8377026,
                  },
                ],
                sentiment: 0.5038062334060669,
                similarity: 0.5983918905258179,
              },
            ],
            'wheresthematch.com': [
              {
                url: 'https://www.wheresthematch.com/live-radio-commentary/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 2.907801,
                  },
                  {
                    name: 'Sports',
                    weight: 1.006942,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/TV Networks & Stations',
                    weight: 0.9706833,
                  },
                  {
                    name: 'News',
                    weight: 0.9681848,
                  },
                  {
                    name: 'News/Sports News',
                    weight: 0.9681848,
                  },
                  {
                    name: 'Arts & Entertainment/Music & Audio',
                    weight: 0.9652364,
                  },
                  {
                    name: 'Arts & Entertainment/Music & Audio/Radio',
                    weight: 0.9652364,
                  },
                  {
                    name: 'Sports/Team Sports',
                    weight: 0.9060546,
                  },
                  {
                    name: 'Sports/Team Sports/Soccer',
                    weight: 0.9060546,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/Online Video',
                    weight: 0.8992716,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video',
                    weight: 0.8992716,
                  },
                ],
                sentiment: 0.33813631534576416,
                similarity: 0.5716322660446167,
              },
            ],
          },
        },
        {
          name: 'Intent group 7',
          keywords: [
            '"Mobile streaming services like Netflix and Spotify provide entertainment on the go  from movies to music to podcasts."',
          ],
          categoryDistribution: {
            totalCount: 55,
            totalUniqueCount: 29,
            distribution: [
              {
                name: 'Arts & Entertainment',
                percentage: 18.181818181818183,
                count: 10,
              },
              {
                name: 'Arts & Entertainment/TV & Video/Online Video',
                percentage: 10.909090909090908,
                count: 6,
              },
              {
                name: 'Arts & Entertainment/Music & Audio/Music Streams & Downloads',
                percentage: 7.2727272727272725,
                count: 4,
              },
              {
                name: 'Arts & Entertainment/TV & Video',
                percentage: 7.2727272727272725,
                count: 4,
              },
              {
                name: 'Arts & Entertainment/Music & Audio',
                percentage: 3.6363636363636362,
                count: 2,
              },
              {
                name: 'Internet & Telecom/Service Providers',
                percentage: 3.6363636363636362,
                count: 2,
              },
              {
                name: 'Internet & Telecom/Service Providers/Cable & Satellite Providers',
                percentage: 3.6363636363636362,
                count: 2,
              },
              {
                name: 'News',
                percentage: 3.6363636363636362,
                count: 2,
              },
              {
                name: 'Internet & Telecom',
                percentage: 3.6363636363636362,
                count: 2,
              },
              {
                name: 'Arts & Entertainment/TV & Video/TV Networks & Stations',
                percentage: 3.6363636363636362,
                count: 2,
              },
              {
                name: 'Arts & Entertainment/Entertainment Industry/Recording Industry',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Music & Audio/Urban & Hip-Hop',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Consumer Electronics/Media Streaming Devices',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/TV & Video/TV Shows & Programs',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Shopping',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Celebrities & Entertainment News',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Entertainment Industry',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Computers & Electronics/Consumer Electronics',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Music & Audio/Pop Music',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Computers & Electronics',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Movies/Drama Films',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'News/Technology News',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'People & Society/Religion & Belief',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'People & Society',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Music & Audio/Music Reference',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Shopping/Consumer Resources',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/Movies/Movie Reference',
                percentage: 1.8181818181818181,
                count: 1,
              },
              {
                name: 'Arts & Entertainment/TV & Video/TV Guides & Reference',
                percentage: 1.8181818181818181,
                count: 1,
              },
            ],
          },
          sentimentDistribution: {
            totalCount: 10,
            positiveCount: 8,
            negativeCount: 2,
            percentPositive: 80,
            percentNegative: 20,
          },
          domainMap: {
            'followfollow.com': [
              {
                url: 'https://www.followfollow.com/forum/threads/illegal-sports-streaming-–-accessing-the-inaccessible.192196/page-3/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 2.4202971,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/Online Video',
                    weight: 0.99225074,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video',
                    weight: 0.99225074,
                  },
                  {
                    name: 'Arts & Entertainment/Music & Audio',
                    weight: 0.92105126,
                  },
                  {
                    name: 'Arts & Entertainment/Music & Audio/Music Streams & Downloads',
                    weight: 0.92105126,
                  },
                ],
                sentiment: 0.007861465215682983,
                similarity: 0.6577146649360657,
              },
            ],
            'telegraph.co.uk': [
              {
                url: 'https://www.telegraph.co.uk/books/non-fiction/favourite-song-spotify-glenn-mcdonald-review/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 6.5739164,
                  },
                  {
                    name: 'Arts & Entertainment/Celebrities & Entertainment News',
                    weight: 0.97861564,
                  },
                  {
                    name: 'Arts & Entertainment/Music & Audio/Pop Music',
                    weight: 0.975014,
                  },
                  {
                    name: 'Arts & Entertainment/Music & Audio/Urban & Hip-Hop',
                    weight: 0.94384325,
                  },
                  {
                    name: 'Arts & Entertainment/Music & Audio/Music Streams & Downloads',
                    weight: 0.9428913,
                  },
                  {
                    name: 'Arts & Entertainment/Music & Audio/Music Reference',
                    weight: 0.90801704,
                  },
                  {
                    name: 'Arts & Entertainment/Music & Audio',
                    weight: 0.8750957,
                  },
                  {
                    name: 'Arts & Entertainment/Entertainment Industry',
                    weight: 0.83448225,
                  },
                  {
                    name: 'Arts & Entertainment/Entertainment Industry/Recording Industry',
                    weight: 0.83448225,
                  },
                ],
                sentiment: -0.20387700200080872,
                similarity: 0.6162689924240112,
              },
            ],
            'theboltonnews.co.uk': [
              {
                url: 'https://www.theboltonnews.co.uk/news/24700542.much-streaming-services-cost-us-uk/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 2.0068583,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 1.0415524,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/Online Video',
                    weight: 0.99867046,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers',
                    weight: 0.8977069,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/Cable & Satellite Providers',
                    weight: 0.8977069,
                  },
                ],
                sentiment: -0.1868174821138382,
                similarity: 0.603356122970581,
              },
            ],
            'theguardian.com': [
              {
                url: 'https://www.theguardian.com/tv-and-radio/2024/dec/09/streaming-has-made-watching-tv-an-insular-experience-says-london-bishop/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 3.094168,
                  },
                  {
                    name: 'News',
                    weight: 1.1744578,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/TV Networks & Stations',
                    weight: 0.98728126,
                  },
                  {
                    name: 'People & Society/Religion & Belief',
                    weight: 0.9027962,
                  },
                  {
                    name: 'People & Society',
                    weight: 0.9027962,
                  },
                ],
                sentiment: 0.017013370990753174,
                similarity: 0.6242052316665649,
              },
            ],
            'thesun.ie': [
              {
                url: 'https://www.thesun.ie/tech/9703930/best-free-tv-streaming-apps-freevee-pluto-uktv-play/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 2.0669212,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/Online Video',
                    weight: 0.99949825,
                  },
                  {
                    name: 'Shopping',
                    weight: 0.8375735,
                  },
                  {
                    name: 'Shopping/Consumer Resources',
                    weight: 0.8375735,
                  },
                  {
                    name: 'Shopping/Consumer Resources/Product Reviews & Price Comparisons',
                    weight: 0.8375735,
                  },
                ],
                sentiment: 0.25483763217926025,
                similarity: 0.6535999774932861,
              },
            ],
            'wikihow.com': [
              {
                url: 'https://www.wikihow.com/Watch-a-Music-Video-on-Spotify/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 2.0747502,
                  },
                  {
                    name: 'News',
                    weight: 1.076085,
                  },
                  {
                    name: 'News/Technology News',
                    weight: 0.998393,
                  },
                  {
                    name: 'Arts & Entertainment/Music & Audio/Music Streams & Downloads',
                    weight: 0.90098506,
                  },
                ],
                sentiment: 0.16593465209007263,
                similarity: 0.6030414700508118,
              },
            ],
            'yahoo.com': [
              {
                url: 'https://www.yahoo.com/tech/heres-see-spotify-wrapped-132750549.html/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 2.553132,
                  },
                  {
                    name: 'Arts & Entertainment/Music & Audio/Music Streams & Downloads',
                    weight: 0.9990601,
                  },
                ],
                sentiment: 0.31993305683135986,
                similarity: 0.6576904058456421,
              },
              {
                url: 'https://www.yahoo.com/entertainment/best-live-tv-streaming-services-192650258.html/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 2.1654406,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/Online Video',
                    weight: 0.9962805,
                  },
                  {
                    name: 'Internet & Telecom',
                    weight: 0.98401856,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers',
                    weight: 0.9683653,
                  },
                  {
                    name: 'Internet & Telecom/Service Providers/Cable & Satellite Providers',
                    weight: 0.9683653,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video',
                    weight: 0.95116794,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/TV Networks & Stations',
                    weight: 0.95116794,
                  },
                  {
                    name: 'Computers & Electronics',
                    weight: 0.91470736,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics/Media Streaming Devices',
                    weight: 0.8107759,
                  },
                  {
                    name: 'Computers & Electronics/Consumer Electronics',
                    weight: 0.8107759,
                  },
                ],
                sentiment: 0.1075320914387703,
                similarity: 0.6303485631942749,
              },
              {
                url: 'https://www.yahoo.com/lifestyle/best-free-streaming-platforms-tv-144057674.html/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 1.6473789,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video',
                    weight: 0.9998437,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/Online Video',
                    weight: 0.9998437,
                  },
                ],
                sentiment: 0.3654687702655792,
                similarity: 0.6134958267211914,
              },
              {
                url: 'https://www.yahoo.com/entertainment/vow-2024-streaming-watch-stream-035526118.html/',
                language: 'en',
                categories: [
                  {
                    name: 'Arts & Entertainment',
                    weight: 6.4662995,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/Online Video',
                    weight: 0.9991912,
                  },
                  {
                    name: 'Arts & Entertainment/Movies/Movie Reference',
                    weight: 0.98007846,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/TV Shows & Programs',
                    weight: 0.9634873,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video/TV Guides & Reference',
                    weight: 0.962379,
                  },
                  {
                    name: 'Arts & Entertainment/TV & Video',
                    weight: 0.962379,
                  },
                  {
                    name: 'Arts & Entertainment/Movies/Drama Films',
                    weight: 0.8516943,
                  },
                ],
                sentiment: 0.16940099000930786,
                similarity: 0.6058856248855591,
              },
            ],
          },
        },
      ],
    },
  ],
};
