export interface AdvertiserFormData {
  name: string;
  url: string;
  country: string;
  companyId: string | null;
  teamId: string | null;
  userId: string | null;
}

export interface AdvertiserItem extends AdvertiserFormData {
  id: string;
  createdAt: string;
}

export interface AdvertiserResponse extends AdvertiserFormData {
  id: string;
  creationDate: string;
  createdAt: string;
}

export interface AdvertiserState extends AdvertiserFormData {
  isLoading: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isUpdating: boolean;
  id: string;
  advertiserToDeleteId: string;
  createdAt: string;
  errorMessage: string | null;
}

export const ADVERTISER_URL = 'advertiser';
