import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdvertiserFormData, AdvertiserResponse, AdvertiserState } from './constants';

export const ADVERTISER_SLICE_NAME = 'advertiser';

export const initialState: AdvertiserState = {
  name: '',
  id: '',
  url: '',
  country: 'GB',
  companyId: null,
  teamId: null,
  userId: null,
  isLoading: false,
  isCreated: false,
  isUpdated: false,
  isUpdating: false,
  advertiserToDeleteId: '',
  createdAt: '',
  errorMessage: null,
};

export const advertiserSlice = createSlice({
  name: ADVERTISER_SLICE_NAME,
  initialState,
  reducers: {
    saveAdvertiser: (state: AdvertiserState) => {
      state.isLoading = true;
    },
    saveAdvertiserFailure: (
      state: AdvertiserState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
    },
    saveAdvertiserSuccess: (state: AdvertiserState, { payload }) => {
      state.isLoading = false;
      state.isCreated = true;
      state.id = payload.id;
    },
    clearAdvertiser: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteAdvertiser: (state: AdvertiserState, { payload }: PayloadAction<{ id: string }>) => {
      state.isLoading = true;
    },
    deleteAdvertiserSuccess: (state: AdvertiserState) => {
      state.isLoading = false;
      state.advertiserToDeleteId = '';
      state.isCreated = false;
    },
    deleteAdvertiserFailure: (
      state: AdvertiserState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.isCreated = false;
    },
    setAdvertiserToDeleteId: (state: AdvertiserState, { payload }: PayloadAction<string>) => {
      state.advertiserToDeleteId = payload;
    },
    updateAdvertiser: (state: AdvertiserState) => {
      state.isLoading = true;
    },
    updateAdvertiserSuccess: (state: AdvertiserState) => {
      state.isLoading = false;
      state.isCreated = true;
    },
    updateAdvertiserFailure: (
      state: AdvertiserState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.isCreated = false;
    },
    updateValues: (state: AdvertiserState, { payload }: PayloadAction<AdvertiserFormData>) => {
      return {
        ...state,
        ...payload,
      };
    },
    setAdvertiserToUpdateId: (state: AdvertiserState, { payload }: PayloadAction<string>) => {
      state.id = payload;
    },
    getAdvertiserById: (state: AdvertiserState, { payload }: PayloadAction<string>) => {
      state.isLoading = true;
      state.id = payload;
    },
    getAdvertiserByIdSuccess: (
      state: AdvertiserState,
      { payload }: PayloadAction<AdvertiserResponse>,
    ) => {
      state.isLoading = false;
      state.name = payload.name;
      state.companyId = payload.companyId;
      state.country = payload.country;
      state.teamId = payload.teamId;
      state.userId = payload.userId;
      state.url = payload.url;
      state.createdAt = payload.createdAt;
    },
    getAdvertiserByIdFailure: (
      state: AdvertiserState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    },
  },
});
export const {
  saveAdvertiser,
  saveAdvertiserFailure,
  saveAdvertiserSuccess,
  clearAdvertiser,
  deleteAdvertiser,
  deleteAdvertiserFailure,
  deleteAdvertiserSuccess,
  setAdvertiserToDeleteId,
  updateAdvertiser,
  updateAdvertiserFailure,
  updateAdvertiserSuccess,
  updateValues,
  setAdvertiserToUpdateId,
  getAdvertiserById,
  getAdvertiserByIdSuccess,
  getAdvertiserByIdFailure,
} = advertiserSlice.actions;

export const advertiser = advertiserSlice.reducer;
