import { lazy } from 'react';

export const HomePage = lazy(() =>
  import('pages/HomePage').then((module) => ({ default: module.HomePage })),
);
export const SegmentModeSelectionPage = lazy(() =>
  import('pages/segmentPages/SegmentModeSelectionPage').then((module) => ({
    default: module.SegmentModeSelectionPage,
  })),
);
export const AutomatedStepPage = lazy(() =>
  import('pages/segmentPages/AutomatedStepPage').then((module) => ({
    default: module.AutomatedStepPage,
  })),
);
export const ManualStepPage = lazy(() =>
  import('pages/segmentPages/ManualStepPage').then((module) => ({
    default: module.ManualStepPage,
  })),
);
export const TargetingOption = lazy(() =>
  import('pages/segmentPages/SegmentModeSelectionPage').then((module) => ({
    default: module.TargetingOption,
  })),
);
export const SegmentBucketsPage = lazy(() =>
  import('pages/segmentPages/SegmentBucketsPage').then((module) => ({
    default: module.SegmentBucketsPage,
  })),
);
export const SegmentInfoPage = lazy(() =>
  import('pages/segmentPages/SegmentInfoPage').then((module) => ({
    default: module.SegmentInfoPage,
  })),
);
export const SegmentDetailsPage = lazy(() =>
  import('pages/segmentPages/SegmentDetailsPage').then((module) => ({
    default: module.SegmentDetailsPage,
  })),
);
export const SegmentSavePage = lazy(() =>
  import('pages/segmentPages/SegmentSavePage').then((module) => ({
    default: module.SegmentSavePage,
  })),
);
export const SegmentListPage = lazy(() =>
  import('pages/segmentPages/SegmentListPage').then((module) => ({
    default: module.SegmentListPage,
  })),
);
export const AdvertiserListPage = lazy(() =>
  import('pages/advertiserPages/AdvertiserListPage').then((module) => ({
    default: module.AdvertiserListPage,
  })),
);
export const PixelListPage = lazy(() =>
  import('pages/pixelPages/PixelListPage').then((module) => ({
    default: module.PixelListPage,
  })),
);
export const MeasurementPage = lazy(() =>
  import('pages/MeasurementPage').then((module) => ({
    default: module.MeasurementPage,
  })),
);
export const WelcomePage = lazy(() =>
  import('pages/WelcomePage').then((module) => ({
    default: module.WelcomePage,
  })),
);
export const UniversityPage = lazy(() =>
  import('pages/UniversityPage').then((module) => ({
    default: module.UniversityPage,
  })),
);
export const LibraryPage = lazy(() =>
  import('pages/LibraryPage').then((module) => ({
    default: module.LibraryPage,
  })),
);
export const NotFoundPage = lazy(() =>
  import('pages/NotFoundPage').then((module) => ({
    default: module.NotFoundPage,
  })),
);

export const KeywordGroupFormPage = lazy(() =>
  import('pages/adminPanel/keywordGroupPages/KeywordGroupFormPage').then((module) => ({
    default: module.KeywordGroupFormPage,
  })),
);

export const KeywordGroupListPage = lazy(() =>
  import('pages/adminPanel/keywordGroupPages/KeywordGroupListPage').then((module) => ({
    default: module.KeywordGroupListPage,
  })),
);

export const CustomCategoriesListPage = lazy(() =>
  import('pages/adminPanel/customCategoryPages/CustomCategoriesListPage').then((module) => ({
    default: module.CustomCategoriesListPage,
  })),
);

export const CustomCategoryPage = lazy(() =>
  import('pages/adminPanel/customCategoryPages/CustomCategoryPage').then((module) => ({
    default: module.CustomCategoryPage,
  })),
);

export const CompanyListPage = lazy(() =>
  import('pages/adminPanel/companyPages/CompanyListPage').then((module) => ({
    default: module.CompanyListPage,
  })),
);

export const TeamListPage = lazy(() =>
  import('pages/adminPanel/teamPages/TeamListPage').then((module) => ({
    default: module.TeamListPage,
  })),
);

export const UserPage = lazy(() =>
  import('pages/adminPanel/userPages/UserPage').then((module) => ({
    default: module.UserPage,
  })),
);

export const ExclusionListPage = lazy(() =>
  import('pages/adminPanel/exclusionListPages/ExclusionListPage').then((module) => ({
    default: module.ExclusionListPage,
  })),
);

export const AuditLogPage = lazy(() =>
  import('pages/adminPanel/auditLogPages/AuditLogPage').then((module) => ({
    default: module.AuditLogPage,
  })),
);

export const SearchByVectorPage = lazy(() =>
  import('pages/adminPanel/searchByVector/SearchByVectorPage').then((module) => ({
    default: module.SearchByVectorPage,
  })),
);

export const ContextaPage = lazy(() =>
  import('pages/adminPanel/contexta/ContextaPage').then((module) => ({
    default: module.ContextaPage,
  })),
);

export const SegmentGroupListPage = lazy(() =>
  import('pages/adminPanel/segmentGroupPages/SegmentGroupListPage').then((module) => ({
    default: module.SegmentGroupListPage,
  })),
);

export const DomainListPage = lazy(() =>
  import('pages/adminPanel/domainListPages/DomainListPage').then((module) => ({
    default: module.DomainListPage,
  })),
);

export const TagWrappingToolPage = lazy(() =>
  import('pages/TagWrappingToolPage').then((module) => ({ default: module.TagWrappingToolPage })),
);
