import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { advertiserApi } from '../advertiserApi';
import { advertiserListApiSlice } from '../advertiserListApiSlice';
import { getAdvertiser } from '../advertiserSelectors';
import { saveAdvertiserFailure, saveAdvertiserSuccess } from '../advertiserSlice';
import { AdvertiserFormData, AdvertiserResponse, AdvertiserState } from '../constants';

export function* saveAdvertiserSaga() {
  try {
    const advertiser: AdvertiserState = yield select(getAdvertiser);
    const advertiserData: AdvertiserFormData = {
      name: advertiser.name,
      url: advertiser.url,
      country: advertiser.country,
      companyId: advertiser.companyId,
      teamId: advertiser.teamId,
      userId: advertiser.userId,
    };
    const response: AdvertiserResponse = yield call(advertiserApi.saveAdvertiser, advertiserData);

    yield put(saveAdvertiserSuccess(response));
    yield put(advertiserListApiSlice.util.invalidateTags(['Advertiser']));
  } catch (e) {
    notifyError(e);

    yield put(
      saveAdvertiserFailure({
        message: i18next.t('pages.advertiser.errors.advertiserSaveFailed'),
      }),
    );
  }
}
