import { api } from 'services/api';

import { IntentEstimateDiagramRequest, IntentEstimateDiagramResponse } from './types';

const getDiagramData = (data: IntentEstimateDiagramRequest) => {
  return api.get<IntentEstimateDiagramResponse>('sales-tool/vector-search', {
    params: { segmentId: data.segmentId },
  });
};

export const intentEstimateDiagramApi = {
  getDiagramData,
};
