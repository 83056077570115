import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';
import { v4 as uuid } from 'uuid';

import {
  EmotionTargetOption,
  Entity,
  TargetingOptionId,
} from 'modules/segment/segmentForms/ManualStepForm/types';

import { checkNodeChildren, handleCategoryNodeCheck } from './categoryNodeMethods';
import {
  defaultSegmentExpiredDate,
  defaultSensitiveSubjects,
  defaultTrackingTagsState,
} from './constants';
import { defaultBucketState } from './defaultBucketState';
import { PerformanceMetrics, SegmentDetailsResponse } from './segmentApiTypes';
import { FileDescription } from './segmentForms/ManualStepForm/components/Keywords/components/UploadKeywords';
import {
  AccuracyThresholdTextValues,
  CreateStepValues,
  IntervalFilter,
  IntervalFilters,
  ManualStepValues,
  ReportSegment,
  SegmentMode,
  SegmentState,
  SegmentStatus,
  SentimentType,
  SentimentVariant,
  SspOptionTypes,
} from './types';

export const SEGMENT_SLICE_NAME = 'segment';

const initialState: SegmentState = {
  name: '',
  domainInclusionListId: '',
  domainExclusionListId: '',
  status: null,
  currentBucket: defaultBucketState,
  buckets: [],
  sensitiveSubjects: defaultSensitiveSubjects,
  mode: SegmentMode.manual,
  ssp: null,
  async: false,
  realtime: true,
  dsp: null,
  dspSeatId: null,
  sspDealId: null,
  sspSeatId: null,
  sspSegmentId: null,
  expiredDate: null,
  debugUntil: null,
  debugUntilStatus: '',
  geo: [],
  isEditable: true,
  description: '',
  isLoading: false,
  isCreated: false,
  isUpdating: false,
  isUpdated: false,
  isDeleted: false,
  isDeleting: false,
  companyId: '',
  teamId: '',
  segmentToCopyId: null,
  deletedSegmentId: null,
  performance: {},
  accuracyThreshold: 'low',
  tags: defaultTrackingTagsState,
  id: '',
  labels: [],
  filters: {
    interval: IntervalFilters.daily,
  },
  parentId: null,
  group: null,
  hideNameForPartner: false,
  segmentReportLoading: false,
  reportSegment: { prometheus: [], dailyMatched: [] },
  publicSegment: false,
};

export const segmentSlice = createSlice({
  name: SEGMENT_SLICE_NAME,
  initialState,
  reducers: {
    changeSegmentMode: (state: SegmentState, { payload }: PayloadAction<SegmentMode>) => {
      state.mode = payload;
      state.currentBucket = defaultBucketState;
      state.description = '';
      state.sensitiveSubjects = defaultSensitiveSubjects;
      state.performance = {};
      state.buckets = [];
    },
    setCurrentBucket: (state: SegmentState, { payload }: PayloadAction<ManualStepValues>) => {
      state.currentBucket = payload;
    },
    restoreSegment: (state: SegmentState, { payload }: PayloadAction<Partial<SegmentState>>) => ({
      ...state,
      ...payload,
      currentBucket: {
        ...state.currentBucket,
        ...payload.currentBucket,
        // TODO: this logic is needed to prevent the resetting of the categoriesDataObject to the default state in case if it was restored, we need to get rid of it in the scope of the CheckboxTree refactoring
        categoriesDataObject: Object.values(state.currentBucket.categoriesDataObject).length
          ? state.currentBucket.categoriesDataObject
          : {},
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveCurrentBucket: (state: SegmentState, { payload }: PayloadAction<{ navPath: string }>) => {
      const bucketId = state.currentBucket.id;

      if (bucketId) {
        const updatedBucketIndex = state.buckets.findIndex(({ id }) => id === bucketId);

        state.buckets[updatedBucketIndex] = state.currentBucket;
      } else {
        state.currentBucket.id = uuid();
        state.buckets.push(state.currentBucket);
      }

      state.currentBucket = defaultBucketState;
    },
    deleteBucket: (state: SegmentState, { payload }: PayloadAction<string>) => {
      state.buckets = state.buckets.filter(({ id }) => id !== payload);
    },
    resetBuckets: (state: SegmentState) => {
      state.buckets = [];
      state.currentBucket = defaultBucketState;
    },
    updateValues: (state: SegmentState, { payload }: PayloadAction<Partial<CreateStepValues>>) => {
      if (payload.ssp === 'MG') {
        return {
          ...state,
          ...payload,
          async: true,
          realtime: false,
        };
      }

      if (payload.ssp === 'IE') {
        return {
          ...state,
          ...payload,
          async: true,
          realtime: true,
        };
      }

      if (payload.sspType === SspOptionTypes.rulesBased) {
        return {
          ...state,
          ...payload,
          async: true,
          realtime: false,
        };
      }

      if (payload.sspType === SspOptionTypes.liveBid) {
        return {
          ...state,
          ...payload,
          async: false,
          realtime: true,
        };
      }

      return {
        ...state,
        ...payload,
      };
    },
    clearSegment: () => initialState,
    toggleIncluded: (state: SegmentState, { payload }: PayloadAction<TargetingOptionId>) => {
      state.currentBucket[payload].isIncluded = !state.currentBucket[payload].isIncluded;

      return state;
    },
    addKeywords: (state: SegmentState, { payload }: PayloadAction<string[]>) => {
      state.currentBucket.keywords.isTouched = true;

      state.currentBucket.keywords.keywordsValues = [
        ...new Set([...state.currentBucket.keywords.keywordsValues, ...payload]),
      ];

      return state;
    },
    addUploadedKeywords: (state: SegmentState, { payload }: PayloadAction<FileDescription[]>) => {
      state.currentBucket.keywords.isTouched = true;
      const stateFiles = state.currentBucket.keywords.uploadedValues;
      const currentKeywords = state.currentBucket.keywords.keywordsValues;
      const newKeywords = payload
        .flatMap((file) => file.values)
        .filter((value) => !currentKeywords.includes(value)); // Filter out duplicates

      const previousKeywordsText = state.currentBucket.keywords.keywordsText || '';
      const previousKeywordsSet = new Set(
        previousKeywordsText.split(',').map((keyword) => keyword.trim()),
      ); // Create a set of existing keywords

      // Filter out keywords that are already in `previousKeywordsText`
      const uniqueNewKeywords = newKeywords.filter((keyword) => !previousKeywordsSet.has(keyword));

      // Only append unique new keywords to `keywordsText`
      if (uniqueNewKeywords.length > 0) {
        state.currentBucket.keywords.keywordsText = previousKeywordsText
          ? `${previousKeywordsText}, ${uniqueNewKeywords.join(', ')}`
          : uniqueNewKeywords.join(', ');
      }

      state.currentBucket.keywords.keywordsValues = [
        ...new Set([...state.currentBucket.keywords.keywordsValues, ...newKeywords]),
      ];
      payload.forEach(
        (file) => !stateFiles.find(({ key }) => key === file.key) && stateFiles.push(file),
      );
    },

    removeUploadedKeywords: (state: SegmentState, { payload }: PayloadAction<string>) => {
      // Remove the file from uploadedValues
      const removedFile = state.currentBucket.keywords.uploadedValues.find(
        ({ key }) => key === payload,
      );

      if (!removedFile) {
        return state; // If the file doesn't exist, return the current state.
      }

      state.currentBucket.keywords.uploadedValues =
        state.currentBucket.keywords.uploadedValues.filter(({ key }) => key !== payload);

      // Remove the keywords from keywordsValues
      state.currentBucket.keywords.keywordsValues =
        state.currentBucket.keywords.keywordsValues.filter(
          (keyword) => !removedFile.values.includes(keyword),
        );

      // Update keywordsText by removing the removed keywords
      const currentKeywordsTextSet = new Set(
        state.currentBucket.keywords.keywordsText.split(',').map((keyword) => keyword.trim()),
      );

      removedFile.values.forEach((keyword) => currentKeywordsTextSet.delete(keyword));

      state.currentBucket.keywords.keywordsText = [...currentKeywordsTextSet].join(', ');

      return state;
    },

    removeKeyword: (state: SegmentState, { payload }: PayloadAction<string>) => {
      state.currentBucket.keywords.isTouched = true;
      state.currentBucket.keywords.keywordsValues =
        state.currentBucket.keywords.keywordsValues.filter((value) => value !== payload);
      state.currentBucket.keywords.keywordsText =
        state.currentBucket.keywords.keywordsValues.join(', ');

      return state;
    },
    clearAllKeywords: (state: SegmentState) => {
      state.currentBucket.keywords.isTouched = true;
      state.currentBucket.keywords.keywordsText = '';
      state.currentBucket.keywords.keywordsValues = [];

      return state;
    },
    toggleSentiment: (state: SegmentState, { payload }: PayloadAction<SentimentVariant>) => {
      state.currentBucket.sentiments.isTouched = true;

      if (payload === SentimentType.positive) {
        const sentimentMaxValue = state.currentBucket.sentiments.sentimentsValue.max;

        state.currentBucket.sentiments.sentimentsValue.max = sentimentMaxValue === 0 ? 1 : 0;
      }

      if (payload === SentimentType.negative) {
        const sentimentMinValue = state.currentBucket.sentiments.sentimentsValue.min;

        state.currentBucket.sentiments.sentimentsValue.min = sentimentMinValue === 0 ? -1 : 0;
      }
    },
    setEntity: (state: SegmentState, { payload }: PayloadAction<string[]>) => {
      state.currentBucket.entity.isTouched = true;

      const newEntities: Entity[] = payload.map((item) => {
        // eslint-disable-next-line camelcase
        const [keyword, entity_type] = item.split(' - ');

        // eslint-disable-next-line camelcase
        return { keyword, entity_type } as Entity;
      });

      if (payload.length === 0) {
        state.currentBucket.entity.entity = [];
      }

      state.currentBucket.entity.entity = newEntities;
    },

    removeEntityTag: (state: SegmentState, { payload }: PayloadAction<string>) => {
      state.currentBucket.entity.isTouched = true;
      const [keyword] = payload.split(' - ');

      state.currentBucket.entity.entity = state.currentBucket.entity.entity.filter(
        (spec) => spec.keyword !== keyword,
      );
    },
    setEmotions: (state: SegmentState, { payload }: PayloadAction<string[]>) => {
      state.currentBucket.emotions.isTouched = true;
      state.currentBucket.emotions.emotionsValues = payload as EmotionTargetOption[];

      return state;
    },
    setTypeByIntentPrompt: (state: SegmentState, { payload }: PayloadAction<boolean>) => {
      state.currentBucket.keywords.isTouched = true;
      state.currentBucket.keywords.intentPrompt = payload;

      return state;
    },
    setSensitiveSubjects: (state: SegmentState, { payload }: PayloadAction<string[]>) => {
      state.sensitiveSubjects.isTouched = true;
      state.sensitiveSubjects.sensitiveSubjectsValues = payload;

      return state;
    },
    setPersonas: (state: SegmentState, { payload }: PayloadAction<string[]>) => {
      state.currentBucket.personas.isTouched = true;
      state.currentBucket.personas.personasValues = payload;

      return state;
    },
    setLanguages(state: SegmentState, { payload }: PayloadAction<string[]>) {
      state.currentBucket.languages.isTouched = true;
      state.currentBucket.languages.languagesValues = payload;

      return state;
    },
    setSsp(state: SegmentState, { payload }: PayloadAction<string>) {
      state.ssp = payload;
      if (payload !== 'Xandr') {
        state.async = false;
        state.realtime = true;
      }
      if (payload === 'MG') {
        state.async = true;
        state.realtime = false;
      }
      if (payload === 'IE') {
        state.async = true;
        state.realtime = true;
      }

      return state;
    },
    setName(state: SegmentState, { payload }: PayloadAction<string>) {
      state.name = payload;

      return state;
    },
    setCategories: (state: SegmentState, { payload }: PayloadAction<string[]>) => {
      state.currentBucket.categories.isTouched = true;
      state.currentBucket.categories.categoriesValues = payload;

      return state;
    },
    saveSegment: (state: SegmentState) => {
      state.isLoading = true;
    },
    saveSegmentNanoBot: (
      state: SegmentState,
      { payload }: PayloadAction<{ intentPrompt: string[]; navigate: NavigateFunction }>,
    ) => {
      state.currentBucket.keywords.isTouched = true;
      state.currentBucket.keywords.keywordsValues = [...new Set(payload.intentPrompt)];
      state.isLoading = true;

      return state;
    },
    saveSegmentSuccess: (state: SegmentState, { payload }) => {
      state.isLoading = false;
      state.isCreated = true;
      state.id = payload.id;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveSegmentFailure: (state: SegmentState, { payload }: PayloadAction<{ message: string }>) => {
      state.isLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateSegment: (state: SegmentState, { payload }: PayloadAction<{ id: string }>) => {
      state.isUpdating = true;
      state.isUpdated = false;
    },
    updateSegmentSuccess: (state: SegmentState) => {
      state.isUpdating = false;
      state.isUpdated = true;
    },
    updateSegmentFailure: (
      state: SegmentState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isUpdating = false;
      state.isUpdated = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteSegment: (state: SegmentState, { payload }: PayloadAction<{ id: string | null }>) => {
      state.isDeleting = true;
      state.isDeleted = false;
    },
    deleteSegmentSuccess: (state: SegmentState) => {
      state.isDeleting = false;
      state.isDeleted = true;
    },
    deleteSegmentFailure: (
      state: SegmentState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isDeleting = false;
      state.isDeleted = false;
    },
    setCategoriesDataObject: (state: SegmentState, { payload }) => {
      state.currentBucket.categoriesDataObject = payload;
    },
    setCategoriesSearchString: (state: SegmentState, { payload }: PayloadAction<string>) => {
      state.currentBucket.categoriesSearchString = payload;
    },
    toggleCategoryNodeExpand: (state: SegmentState, { payload }) => {
      state.currentBucket.categoriesDataObject[payload].expanded =
        !state.currentBucket.categoriesDataObject[payload].expanded;
    },
    toggleCategoryNodeCheck: (state: SegmentState, { payload }: PayloadAction<string>) => {
      handleCategoryNodeCheck(state, payload);
    },
    removeCategoryValue: (state: SegmentState, { payload }: PayloadAction<string>) => {
      state.currentBucket.categories.categoriesValues =
        state.currentBucket.categories.categoriesValues.filter((id: string) => id !== payload);
      state.currentBucket.categoriesDataObject[payload].checked = false;
      checkNodeChildren(state, state.currentBucket.categoriesDataObject[payload], false);
    },
    removeAllCategoriesValues: (state: SegmentState) => {
      state.currentBucket.categories.categoriesValues = [];
      Object.values(state.currentBucket.categoriesDataObject).forEach((node) => {
        state.currentBucket.categoriesDataObject[node.id].checked = false;
        checkNodeChildren(state, state.currentBucket.categoriesDataObject[node.id], false);
      });
    },
    setBuckets: (state: SegmentState, { payload }: PayloadAction<ManualStepValues[]>) => {
      state.buckets = payload;
    },
    setPerformance: (state: SegmentState, { payload }: PayloadAction<PerformanceMetrics>) => {
      state.performance = payload;
    },
    resetPerformance: (state: SegmentState) => {
      state.performance.ctr = 0;
      state.performance.attentionMetric = 0;
      state.performance.timeInView = 0;
      state.performance.viewability = 0;
      state.performance.attentionMetricSlider = 0;
    },
    setDescription: (state: SegmentState, { payload }: PayloadAction<string>) => {
      state.description = payload;
    },
    setStatus: (
      state: SegmentState,
      { payload }: PayloadAction<{ status: SegmentStatus; id: string }>,
    ) => {
      state.id = payload.id;
      state.status = payload.status;
    },
    setSegmentInfo: (state: SegmentState, { payload }: PayloadAction<SegmentDetailsResponse>) => {
      // TODO: Refactor to utilize spread syntax
      state.status = payload.status;
      state.companyId = payload.companyId;
      state.name = payload.name;
      state.domainInclusionListId = payload.domainInclusionListId
        ? payload.domainInclusionListId
        : null;
      state.domainExclusionListId = payload.domainExclusionListId
        ? payload.domainExclusionListId
        : null;
      state.parentId = payload.parentId ? payload.parentId : null;
      state.group = payload.group ? payload.group : null;
      state.hideNameForPartner = payload.hideNameForPartner;
      state.description = payload.description;
      state.id = payload.id;
      state.geo = payload.countries as string[];
      state.dsp = payload.dsp;
      state.dspSeatId = payload.dspSeatId;
      state.sspDealId = payload.sspDealId;
      state.sspSeatId = payload.sspSeatId;
      state.sspSegmentId = payload.sspSegmentId;
      state.expiredDate =
        payload.expiredDate !== defaultSegmentExpiredDate ? payload.expiredDate : null;
      state.tags = payload.tags;
      state.debugUntil = payload.debugUntil ? payload.debugUntil : null;
      state.performance = {
        viewability:
          payload.viewability !== undefined
            ? Number(Math.round(parseFloat(payload.viewability * 100 + 'e6')) + 'e-6')
            : payload.ctr,
        ctr:
          payload.ctr != undefined
            ? Number(Math.round(parseFloat(payload.ctr * 100 + 'e6')) + 'e-6')
            : payload.ctr,
        attentionMetric: payload.attentionMetric,
        timeInView: payload.timeInView,
      };
      state.labels = payload.labels.map(({ name }) => name);
      state.publicSegment = payload.publicSegment;
      state.accuracyThreshold =
        payload.accuracyThreshold === undefined ? 'medium' : payload.accuracyThreshold;
    },
    fetchSegmentDetails: (
      state: SegmentState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ id: string; navPath?: string }>,
    ) => {
      state.isLoading = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getBriefUploadData: (state: SegmentState, { payload }: PayloadAction<FormData>) => {
      state.isLoading = true;
    },
    getBriefUploadDataSuccess: (state: SegmentState) => {
      state.isLoading = false;
    },
    copySegment: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state: SegmentState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ id: string; copyText: string }>,
    ) => {},
    changeStatus: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state: SegmentState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ id: string; status: SegmentStatus }>,
    ) => {},
    fetchSegmentDetailsSuccess: (
      state: SegmentState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ navPath: string }>,
    ) => {
      state.isLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchSegmentDetailsFailure: (
      state: SegmentState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.isLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initSegmentDetails: (state: SegmentState, { payload }: PayloadAction<{ id: string }>) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setIsSegmentLoading: (state: SegmentState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setSegmentToCopyId: (state: SegmentState, { payload }: PayloadAction<string | null>) => {
      state.segmentToCopyId = payload;
    },
    setSegmentIsUpdated: (state: SegmentState, { payload }: PayloadAction<boolean>) => {
      state.isUpdated = payload;
    },
    setIntervalFilter: (state: SegmentState, { payload }: PayloadAction<IntervalFilter>) => {
      state.filters.interval = payload;
    },
    setDeletedSegmentId: (state: SegmentState, { payload }: PayloadAction<string | null>) => {
      state.deletedSegmentId = payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refetchSegmentDetails: (state: SegmentState, { payload }: PayloadAction) => {},
    setAccuracyThreshold: (
      state: SegmentState,
      { payload }: PayloadAction<AccuracyThresholdTextValues>,
    ) => {
      state.accuracyThreshold = payload;
    },
    clearDomainList: (state: SegmentState) => {
      state.domainExclusionListId = '';
      state.domainInclusionListId = '';
      state.isIncluded = true;
    },
    getSegmentReportStart: (
      state: SegmentState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ startDate: string; endDate: string; id: string | undefined }>,
    ) => {
      state.segmentReportLoading = true;
    },
    updateSegmentReport: (state: SegmentState, { payload }: PayloadAction<ReportSegment>) => {
      state.reportSegment.prometheus = payload.prometheus;
      state.reportSegment.dailyMatched = payload.dailyMatched;
      state.segmentReportLoading = false;
    },
    reportSegmentFailure: (
      state: SegmentState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      state.segmentReportLoading = false;
    },
  },
});

export const {
  restoreSegment,
  resetBuckets,
  changeSegmentMode,
  setCurrentBucket,
  saveCurrentBucket,
  deleteBucket,
  updateValues,
  toggleIncluded,
  changeStatus,
  addUploadedKeywords,
  removeUploadedKeywords,
  addKeywords,
  clearAllKeywords,
  toggleSentiment,
  setEntity,
  removeEntityTag,
  setEmotions,
  removeKeyword,
  setSensitiveSubjects,
  setPersonas,
  setLanguages,
  setCategories,
  clearSegment,
  saveSegment,
  saveSegmentNanoBot,
  updateSegment,
  updateSegmentSuccess,
  updateSegmentFailure,
  deleteSegment,
  deleteSegmentSuccess,
  deleteSegmentFailure,
  setDeletedSegmentId,
  saveSegmentSuccess,
  saveSegmentFailure,
  setBuckets,
  setPerformance,
  resetPerformance,
  setDescription,
  fetchSegmentDetails,
  fetchSegmentDetailsSuccess,
  fetchSegmentDetailsFailure,
  getBriefUploadData,
  getBriefUploadDataSuccess,
  setSegmentInfo,
  toggleCategoryNodeExpand,
  setCategoriesDataObject,
  setCategoriesSearchString,
  toggleCategoryNodeCheck,
  removeCategoryValue,
  removeAllCategoriesValues,
  initSegmentDetails,
  setSegmentIsUpdated,
  setIntervalFilter,
  setIsSegmentLoading,
  setSegmentToCopyId,
  copySegment,
  setStatus,
  refetchSegmentDetails,
  setSsp,
  setAccuracyThreshold,
  setTypeByIntentPrompt,
  clearDomainList,
  getSegmentReportStart,
  updateSegmentReport,
  reportSegmentFailure,
} = segmentSlice.actions;

export const segment = segmentSlice.reducer;
