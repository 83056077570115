import { UploadFile } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { notificationService } from 'modules/notifications';

import { DOC_FILE_MIME_TYPE, DOCX_FILE_MIME_TYPE, PDF_FILE_MIME_TYPE } from '../constants';

export const useBriefOutlining = () => {
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<UploadFile | null>();
  const [fileFormData, setFileFormData] = useState<FormData>();

  const handleFileUpload = useCallback(
    async ({ file, fileList }: UploadChangeParam<UploadFile>) => {
      const availableFormats: string[] = [
        DOC_FILE_MIME_TYPE,
        DOCX_FILE_MIME_TYPE,
        PDF_FILE_MIME_TYPE,
      ];

      if (!availableFormats.includes(file.type ?? '')) {
        if (file.percent === 100) {
          notificationService.showError({
            message: t('forms.segment.automated.unSupportedFileType'),
          });
        }

        return;
      }

      const fileObjects = fileList
        .map(({ originFileObj }) => originFileObj)
        .filter(Boolean) as RcFile[];

      const formData = new FormData();

      formData.append('file', fileObjects[0]);

      setUploadedFile(file);
      setFileFormData(formData);
    },
    [t],
  );

  const resetUploadedFile = () => setUploadedFile(null);

  return {
    uploadedFile,
    handleFileUpload,
    fileFormData,
    resetUploadedFile,
  };
};
